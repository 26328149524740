import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import * as yup from 'yup';
import { ErrorBox } from './ErrorBox';
import { apiCall } from '../services/apiCall';
import { StyledTextareaAutosize } from './StyledTextareaAutosize';

export const AddRoute = ({doneCb, deliveryMen}) => {
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [deliveryMan, setDeliveryMan] = useState({});
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const deliveryMenOptions = [
    {id: 0, name: 'Sem Entregador'},
    ...deliveryMen,
  ];

  const addRoute = useCallback(async () => {
    const schema = yup.object().shape({
      number: yup
                .string()
                .test(
                  'is-number',
                  'Número deve ser um número maior do que 0',
                  (value) => !isNaN(value) && value > 0),
      name: yup.string().required('Nome é obrigatório'),
    });

    const data = {
      number,
      name,
      notes,
    };

    if (deliveryMan.id) {
      data.delivery_man = deliveryMan.id;
    }

    try {
      await schema.validate(data, { abortEarly: false });
      await apiCall('routes', 'post', navigate, data);
      doneCb(true);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setErrors(err.errors);
      } else if (err.response && err.response.status === 400) {
        setErrors(err.response.data.errors);
      } else {
        console.log(err);
      }
    }
  });

  return (
    <Dialog open={true} onClose={() => doneCb(false)}>
      <DialogTitle>Adicionar Rota</DialogTitle>
      <DialogContent sx={{minWidth: 500}}>
        <TextField
          label='Número'
          placeholder='Número'
          type='number'
          sx={{
            mt: 2,
            mb: 1,
            width: '25%',
            mr: '5%',
          }}
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
        <TextField
          label='Nome'
          placeholder='Nome'
          type='text'
          sx={{
            mt: 2,
            mb: 1,
            width: '70%',
          }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel>Entregador</InputLabel>
          <Select 
            label='Entregador'
            placeholder='Entregador'
            sx={{
              mt: 1,
              mb: 1,
              width: '100%',
            }}
            value={deliveryMan.id || '0'}
            onChange={(e) => {
              setDeliveryMan(deliveryMenOptions.find(d => d.id == e.target.value))
            }}
          >
            {deliveryMenOptions.map((deliveryMan) => (
              <MenuItem key={deliveryMan.id} value={deliveryMan.id}>
                {deliveryMan.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <StyledTextareaAutosize
          label='Observações'
          placeholder='Observações'
          type='text'
          sx={{
            mt: 1,
            mb: 2,
          }}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        {errors.length > 0 && (
          <FormGroup sx={{ mt: 2}}>
            <ErrorBox errors={errors} />
          </FormGroup>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => doneCb(false)}>Cancelar</Button>
        <Button onClick={addRoute} variant='contained'>Salvar</Button>
      </DialogActions>

    </Dialog>
  );
};
import { useCallback, useEffect, useState } from 'react';
import { Page } from './Page';
import { Divider, FormControl, InputLabel, Select, Typography, Paper, Button, Chip, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import * as dayjs from 'dayjs';
import { apiCall } from '../services/apiCall';
import { useNavigate } from 'react-router-dom';
import { BarChart } from '@mui/x-charts/BarChart';
import { BetterTable } from './BetterTable';
import 'dayjs/locale/pt-br';

export const Dashboard = () => {
  const [t0, setT0] = useState(dayjs().startOf('month'));
  const [t1, setT1] = useState(dayjs().endOf('month'));
  const [dates, setDates] = useState(false);
  const [series, setSeries] = useState(false);
  const [grouping, setGrouping] = useState('total');
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [period, setPeriod] = useState('day');
  const navigate = useNavigate();


  const getData = useCallback(async () => {
    const res = await apiCall('movements/data-by-day', 'post', navigate, {
      t0: t0.format('YYYY-MM-DD'),
      t1: t1.format('YYYY-MM-DD'),
      grouping
    });

    let key;
    switch (grouping) {
      case 'total':
        key = '';
        break;
      
      case 'roteirizados':
        key = '';
        break;
      
      case 'status1':
        key = 'delivery_status1';
        break;
      
      case 'status2':
        key = 'delivery_status2';
        break;
      
      case 'entregador':
        key = 'delivery_man';
        break;
      
      case 'rota':
        key = 'route';
        break;

      case 'cancelamento':
        key = '';
        break;
      
      case 'cancelamento-status':
        key = 'status';
        break;
      
      case 'cancelamento-entregador':
        key = 'delivery_man';
        break;
      
      case 'cancelamento-tabeliao':
        key = 'tabeliao'
        break;
    }

    console.log(res);

    const strUniqueHelp = (arr) => {
      const newArr = [];
      for (const el of arr) {
        if (el === null || el === undefined) {
          newArr.push('');
        } else {
          newArr.push(el);
        }
      }
      const set = new Set(newArr);
      return Array.from(set);
    };

    const allDatesSet = new Set(res.movements.map(d => dayjs(d.date).startOf(period).format('DD/MM/YYYY')));
    const allDates = Array.from(allDatesSet);

    let datesBuffer = [];
    let seriesBuffer = [];
    let data = {};
    let columns = [
      {field: 'date', headerName: 'Data', width: 0.15 * 1166},
    ];

    // Assuming strUniqueHelp correctly handles and includes an empty string ('') as a distinct status
    let keys = strUniqueHelp(res.movements.map(d => d[key] || '').sort((a, b) => {
      if (!isNaN(a) && !isNaN(b)) {
        return +a - +b;
      }
      if (a === '') {
        return -1;
      }
      if (b === '') {
        return 1;
      }
      return a.localeCompare(b);
    }));

    // Initialize a results buffer to hold accumulations for each status (including empty) across dates
    const resultsBuffer = keys.map(key => ({ key, data: new Array(allDates.length).fill(0) }));
    for (const dateIndex in allDates) {
      const date = allDates[dateIndex];
      const daily = res.movements.filter(d => dayjs(d.date).startOf(period).format('DD/MM/YYYY') === date);

      data[date] = {};

      keys.sort((a, b) => {
        if (!isNaN(a) && !isNaN(b)) {
          return a - b;
        }
        if (a === '') {
          return -1;
        }
        if (b === '') {
          return 1;
        }
        return a.localeCompare(b);
      }).forEach((k, keyIndex) => {
        data[date][k === '' ? 'null' : k] = daily.reduce((acc, d) => {
          const statusMatch = k === '' ? !d[key] : d[key] === k;
          return acc + (statusMatch ? +d.total : 0);
        }, 0);


        // Adjust conditional check here to account for empty status ''
        // Note: The conditional explicitly checks for '' to handle it as 'vazio 1'
        const totalForDate = daily.reduce((acc, d) => {
          const statusMatch = k === '' ? !d[key] : d[key] === k;
          return acc + (statusMatch ? +d.total : 0);
        }, 0);
        resultsBuffer[keyIndex].data[dateIndex] = totalForDate;
      });
    }

    datesBuffer.push({
      data: [...allDates], showMark: true, scaleType: 'band'
    });

    resultsBuffer.forEach(result => {
      let label;
      if (result.key === '') {
        if (grouping === 'total') {
          label = 'Total';
        } else if (grouping === 'roteirizados') {
          label = 'Roteirizados';
        } else if (grouping === 'cancelamento') {
          label = 'Total';
        } else {
          label = 'Vazio';
        }
      } else {
        label = result.key;
      }
      seriesBuffer.push({
        data: result.data, // Arrays of values across all dates
        showMark: true,
        scaleType: 'linear',
        label
      });
      columns.push({ field: result.key === '' ? 'null' : result.key, headerName: label, width: Math.floor(0.85 * 1166 / keys.length)});
    });
    
    setDates(datesBuffer);
    setSeries(seriesBuffer);

    const sortedColumns = columns.sort((a, b) => {
      if (!isNaN(a.headerName) && !isNaN(b.headerName)) {
        return +a.headerName - +b.headerName;
      }
      if (a.headerName === 'Data') {
        return -1;
      }
      if (b.headerName === 'Data') {
        return 1;
      }
      return a.headerName.localeCompare(b.headerName);
    });

    const sortedRows = allDates.map(date => {
      const row = { id: date, date };
      keys.sort((a, b) => {
        if (!isNaN(a) && !isNaN(b)) {
          return a - b;
        }
        if (a === '') {
          return -1;
        }
        if (b === '') {
          return 1;
        }
        return a.localeCompare(b);
      }).forEach(k => {
        if (k === '') {
          k = 'null';
        }
        row[k] = data[date][k];
      });
      return row;
    });

    console.log(sortedRows);
    setColumns(sortedColumns);
    setRows(sortedRows);
  });

  return (
    <Page title='Dashboard'>
      <Paper sx={{
        padding: '1rem',
      }}>
        <Typography sx={{
          fontWeight: '900',
          fontSize: '1.5rem',
        }}>Dashboard</Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />

        <DatePicker
          label='Data inicial'
          sx={{
            mt: 1,
            mb: 1,
            mr: '1%',
            width: '19%'
          }}
          value={t0}
          onChange={(newDate) => {setT0(dayjs(newDate))}}
        />

        <DatePicker
          label='Data final'
          sx={{
            mt: 1,
            mb: 1,
            mr: '1%',
            width: '19%'
          }}
          value={t1}
          onChange={(newDate) => {setT1(dayjs(newDate))}}
        />
        <FormControl
            sx={{
              mt: 1,
              mb: 1,
              mr: '1%',
              width: '20%',
            }}
          >
            <InputLabel>Agrupamento</InputLabel>
            <Select
              fullWidth
              label='Agrupamento'
              placeholder='Agrupamento'
              value={grouping}
              onChange={(e) => setGrouping(e.target.value)}
            >
              <MenuItem value='total'>Total</MenuItem>
              <MenuItem value='roteirizados'>Roteirizados</MenuItem>
              <MenuItem value='status1'>Status de Entrega 1</MenuItem>
              <MenuItem value='status2'>Status de Entrega 2</MenuItem>
              <MenuItem value='entregador'>Entregador</MenuItem>
              <MenuItem value='rota'>Rota</MenuItem>
              <MenuItem value='cancelamento'>Cancelamento</MenuItem>
              <MenuItem value='cancelamento-status'>Cancelamento Status</MenuItem>
              <MenuItem value='cancelamento-entregador'>Cancelamento Entregador</MenuItem>
              <MenuItem value='cancelamento-tabeliao'>Cancelamento Tabelião</MenuItem>
            </Select>
          </FormControl>
                  <FormControl
            sx={{
              mt: 1,
              mb: 1,
              mr: '1%',
              width: '19%',
            }}
          >
            <InputLabel>Período</InputLabel>
            <Select
              fullWidth
              label='Período'
              placeholder='Período'
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
            >
              <MenuItem value='day'>Dia</MenuItem>
              <MenuItem value='week'>Semana</MenuItem>
              <MenuItem value='month'>Mês</MenuItem>
              <MenuItem value='year'>Ano</MenuItem>
            </Select>
          </FormControl>



        <Button variant='contained' color='primary' sx={{ mt: 1, width: '19%', height: '56px' }}
          onClick={getData}
        >Buscar</Button>

        {dates.length > 0 && series.length > 0 && (
          <>
            <BetterTable
              columns={columns}
              rows={rows}
              rowHeight={56}
            />
            <BarChart
              width={1168}
              height={900}
              xAxis={dates}
              series={series}
              margin={{ top: 128 }}
            />
        </>
        )}
      </Paper>
    </Page>
  );
};
import {
  Paper, Typography, Divider, Button, Table, TableCell, TableContainer,
  TableHead, TableRow, TableBody, 
} from "@mui/material";
import { LoadingIndicator } from "./LoadingIndicator";
import { Page } from "./Page";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { MultiFilterOnline } from "./MultiFilterOnline";
import { AddPayment } from "./AddPayment";
import { EditPayment } from "./EditPayment";
import { apiCall } from "../services/apiCall";
import dayjs from "dayjs";

export const Payments = () => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const filterableFields = [
    { value: 'limit', label: 'Limite de resultados', operators: ['='] },
    { value: 't0', label: 'Data Inicial', operators: ['>=', '<=', '='] },
    { value: 't1', label: 'Data Final', operators: ['>=', '<=', '='] },
    { value: 'notary', label: 'Cartório', operators: ['%=%', '=', '!='] },
    { value: 'type', label: 'Tipo', operators: ['%=%', '=', '!='] },
  ];
  const [addingNewPayment, setAddingNewPayment] = useState(false);
  const [notaries, setNotaries] = useState([])
  const [editingPayment, setEditingPayment] = useState(null);
  const [msg, setMsg] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const notaries = await apiCall('notaries', 'get', navigate);
      setNotaries(notaries);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (payments.length === 0) {
      setMsg('Nenhum pagamento encontrado');
    } else if (payments.length === 1) {
      setMsg('1 pagamento encontrado');
    } else {
      setMsg(`${payments.length} pagamentos encontrados`);
    }
  }, [payments]);


  return (
    <>
      {loading && (
        <LoadingIndicator />
      )}

      <Page title="Pagamentos">
        <Paper sx={{
          padding: '1rem',
        }}>
          <Typography sx={{
            fontWeight: '900',
            fontSize: '1.5rem',
          }}>Pagamentos</Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <MultiFilterOnline endpoint={'payments'} fields={filterableFields} setRows={r => setPayments(r.payments)} />
          <Button variant="contained" sx={{ mt: 2 }}
            startIcon={<Add />}
            onClick={() => setAddingNewPayment(true)}>Adicionar pagamento</Button>

          <Typography sx={{ mt: 4, fontWeight: 'bold' }}>{msg}</Typography>

          <TableContainer sx={{ mt: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data Inicial</TableCell>
                  <TableCell>Data Final</TableCell>
                  <TableCell>Cartório</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
             </TableHead>
             <TableBody>
                {payments.map((payment, index) => (
                  <TableRow key={index}>
                    <TableCell>{dayjs(payment.t0, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{dayjs(payment.t1, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{notaries.find(n => n.id == payment.notary).name}</TableCell>
                    <TableCell>{payment.type}</TableCell>
                    <TableCell align="right"><Button variant="outlined" onClick={() => setEditingPayment(payment)}>Editar</Button></TableCell>
                  </TableRow>
                ))}
             </TableBody>
            </Table>
          </TableContainer>


        </Paper>
      </Page>

      {editingPayment && (
        <EditPayment notaries={notaries} payment={editingPayment} doneCb={(res) => {
          if (res) {
            window.location.reload()
          } else {
            setEditingPayment(null);
          }
        }} />
      )}

      {addingNewPayment && (
        <AddPayment notaries={notaries} doneCb={(res) => {
          if (res) {
            window.location.reload()
          } else {
            setAddingNewPayment(false);
          }
        }} />
      )}
    </>
  );
};
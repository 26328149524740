import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
} from '@mui/material';
import { ErrorBox } from './ErrorBox';
import { apiCall } from '../services/apiCall';

export const DeleteUser = ({ doneCb, user }) => {
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const deleteUser = async () => {
    try {
      await apiCall(`users/${user.id}`, 'delete', navigate);
      doneCb(true);
    } catch (err) {
      console.log(err);
      setErrors(err.response.data.errors);
    }
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Excluir Usuário</DialogTitle>
      <DialogContent
        sx={{
          minWidth: 500,
        }}
      >
        <DialogContentText>
          Você tem certeza que deseja excluir o usuário <b>{user.name}</b>?
        </DialogContentText>
        {errors.length > 0 && 
          <FormGroup sx={{ mt: 2}}>
            <ErrorBox errors={errors} />
          </FormGroup>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => doneCb(false)}>Cancelar</Button>
        <Button onClick={deleteUser} color='error'>Excluir</Button>
      </DialogActions>
    </Dialog>
  );
};
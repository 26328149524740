import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import * as yup from 'yup';
import { ErrorBox } from './ErrorBox';
import { apiCall } from '../services/apiCall';
import { StyledTextareaAutosize } from './StyledTextareaAutosize';

export const EditUser = ({ doneCb, user }) => {
  const [name, setName] = useState(user.name);
  const [phone, setPhone] = useState(user.phone || '');
  const [isActive, setIsActive] = useState(user.is_active ? 'true' : 'false');
  const [type, setType] = useState(user.roles[0]);
  const [password, setPassword] = useState('');
  const [extra, setExtra] = useState(user.extra || '');
  const [signatureLine1, setSignatureLine1] = useState(user.signature_line1 || '');
  const [signatureLine2, setSignatureLine2] = useState(user.signature_line2 || '');
  const [errors, setErrors] = useState([]);
  const thisUser = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const editUser = async () => {
    const schema = yup.object().shape({
      password: yup.string()
                  .test('min', 'A senha deve ter pelo menos 4 caracteres',
                        val => !val || val.length >= 4),

      name: yup.string()
              .min(2, 'O nome deve ter pelo menos 2 caracteres')
              .required('Nome é obrigatório'),
    });

    try {
      await schema.validate({
        name,
        password,
      }, {abortEarly: false});

      try {
        await apiCall(`users/${user.id}`, 'put', navigate, { 
          name,
          password,
          type,
          extra,
          signature_line1: signatureLine1,
          signature_line2: signatureLine2,
          phone,
          is_active: isActive
        });
        doneCb(true);
      } catch (err) {
        console.log(err);
        setErrors(err.response.data.errors);
      }
    } catch (err) {
      setErrors(err.errors);
    }
  };

  const handleTextAreaChange = useCallback((e) => {
    setExtra(e.target.value);
  }, []);

  return (
    <Dialog open={true}>
      <DialogTitle>Editar Usuário</DialogTitle>
      <DialogContent
        sx={{
          minWidth: 500,
        }}
      >
        <TextField 
          fullWidth
          label='Nome'
          placeholder='Nome' 
          type='name'
          sx={{
            mb: 1,
            mt: 2,
          }}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <TextField
          fullWidth
          label='Telefone (opcional)'
          placeholder='Telefone (opcional)'
          type='phone'
          sx={{
            mb: 1,
            mt: 1,
          }}
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
        <TextField 
          fullWidth
          label='Senha (opcional)'
          placeholder='Senha (opcional)' 
          type='password'
          sx={{
            mb: 1,
            mt: 1,
          }}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <TextField
          fullWidth
          label='Linha 1 da assinatura (opcional)'
          placeholder='Linha 1 da assinatura (opcional)'
          type='text'
          sx={{
            mb: 1,
            mt: 1,
          }}
          value={signatureLine1}
          onChange={e => setSignatureLine1(e.target.value)}
        />
        <TextField
          fullWidth
          label='Linha 2 da assinatura (opcional)'
          placeholder='Linha 2 da assinatura (opcional)'
          type='text'
          sx={{
            mb: 1,
            mt: 1,
          }}
          value={signatureLine2}
          onChange={e => setSignatureLine2(e.target.value)}
        />
        <StyledTextareaAutosize
          placeholder='Informações adicionais (opcional)'
          minRows={3}
          maxRows={10}
          value={extra}
          onChange={handleTextAreaChange}
          sx={{
            mb: 2,
            mt: 1,
          }}
        />
        <FormControl fullWidth
          sx={{
            mt: 1,
            mb: 2,
          }}
        >
          <InputLabel>Tipo</InputLabel>
          <Select 
            fullWidth
            label='Tipo'
            placeholder='Tipo'
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value='entregador'>Entregador</MenuItem>
            <MenuItem value='admin2'>Administrador 2</MenuItem>
            {thisUser.type === 'admin' &&(
              <MenuItem value='admin'>Administrador</MenuItem>
            )}
          </Select>
        </FormControl>

                <FormControl fullWidth
          sx={{
            mt: 1,
            mb: 2,
          }}
        >
          <InputLabel>Ativo</InputLabel>
          <Select 
            fullWidth
            label='Ativo'
            placeholder='Ativo'
            value={isActive}
            onChange={(e) => setIsActive(e.target.value)}
          >
            <MenuItem value='true'>Ativo</MenuItem>
            <MenuItem value='false'>Inativo</MenuItem>
          </Select>
        </FormControl>


        {errors.length > 0 && (
          <FormGroup sx={{ mt: 2}}>
            <ErrorBox errors={errors} />
          </FormGroup>
        )}

      </DialogContent>
      <DialogActions>
        <Button onClick={() => doneCb(false)}>Cancelar</Button>
        <Button onClick={editUser} variant='contained'>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};
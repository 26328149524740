import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Page } from './Page';
import { Close, FileDownload, More, Search, SendToMobile } from '@mui/icons-material';
import {
  Backdrop, Box, Button, CircularProgress, Divider, FormControl, Select,
  IconButton, InputLabel, Paper, TextField, Typography, MenuItem
} from '@mui/material';
import dayjs from 'dayjs';
import { apiCall } from '../services/apiCall';
import { BetterTable } from './BetterTable';
import Swal from 'sweetalert2';
import { MultiFilter } from './MultiFilter';

export const SingleCancelment = () => {
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(useParams().date);
  const [users, setUsers] = useState([]);
  const [deliveryMen, setDeliveryMen] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [cancelments, setCancelments] = useState([]);
  const [totals, setTotals] = useState([]);
  const navigate = useNavigate();
  console.log(cancelments);

  const filterableFields = [
    {value: 'entregador', label: 'Entregador', operators: ['%=%', '=', '!=']},
    {value: 'tabeliao', label: 'Tabelião', operators: ['%=%', '=', '!=']},
    {value: 'protocolo', label: 'Protocolo', operators: ['%=%', '=', '!=']},
    {value: 'status', label: 'Status', operators: ['%=%', '=', '!=']},
  ];

  const customSrcs = useCallback({
    entregador: (row, filter, op) => {
      const user = users.find((u) => u.id == row.entregador);
      if (!user) {
        return false;
      }

      if (op === '%=%') {
        return user.name.toLowerCase().includes(filter.toLowerCase());
      } else if (op === '=') {
        return user.name == filter;
      } else if (op === '!=') {
        return user.name != filter;
      }
    },
  }, [users]);

  const getCancelments  = useCallback(async () => {
    setLoading(true);
    const data = await apiCall(`cancelments?date=${date}`, 'GET', navigate);
    if (data.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Nenhum cancelamento encontrado',
        text: 'Nenhum cancelamento encontrado para a data selecionada',
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
    setCancelments(data);
    setLoading(false);
  }, []);

  const getUsers = useCallback(async () => {
    setLoading(true);
    const data = await apiCall('users', 'GET', navigate);
    setUsers(data);
    setLoading(false);
  }, []);

  const getRoutes = useCallback(async () => {
    setLoading(true);
    const data = await apiCall('routes', 'GET', navigate);
    setRoutes(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getCancelments();
      await getUsers();
      await getRoutes();
    })();
  }, []);

  useEffect(() => {
    const deliveryMen = [];
    users.forEach((user) => {
      if (user.type === 'entregador') {
        deliveryMen.push(user);
      }
    });
    setDeliveryMen(deliveryMen);
  }, [users]);

  useEffect(() => {
    if (users.length === 0) {
      return;
    }

    const userData = {};
    for (const user of users) {
      if (user.type === 'entregador') {
        userData[user.id] = {
          id: user.id,
          name: user.name,
          visit1: 0,
          visit2: 0,
          cancelment1: 0,
          cancelment2: 0,
          cancelment_not_done: 0,
          total: 0,
        };
      }
    }

    const usersSeen = new Set();

    const movements = [...cancelments];

    for (const protocol of movements) {
      const cancelmentRe = /\d{4}-\d{2}-\d{2}-\d{6}/;
      if (protocol.type === 'visita1') {
        userData[protocol.delivery_man_id].visit1++;
      } else {
        if (cancelmentRe.test(protocol.id)) {
          if (protocol.tabeliao == '1' || protocol.tabeliao == 'Tabeliao 1') {
            userData[protocol.entregador].cancelment1++;
          } else {
            userData[protocol.entregador].cancelment2++;
          }
          if (protocol.status === 'Não realizado') {
            userData[protocol.entregador].cancelment_not_done++;
          }
        } else {
          if (protocol.date_taken.slice(0, 10) == date) {
            userData[protocol.delivery_man_id].visit1++;
          } else {
            userData[protocol.delivery_man_id].visit2++;
          }
        }
      }
      userData[protocol.delivery_man_id || protocol.entregador].total++;
      usersSeen.add(protocol?.delivery_man_id || protocol?.entregador);
    }

    const data = [];
    for (const key in userData) {
      if (usersSeen.has(userData[key].id)) {
        data.push(userData[key]);
      }
    }

    setTotals(data);
  }, [cancelments, users, date]);

  return (
    <Page title={'Cancelamentos do dia ' + dayjs(date).format('DD/MM/YYYY')}>
      {loading > 0 && (
        <>
          <Backdrop open={true} sx={{ zIndex: 9 }} />
          <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-20px', marginLeft: '-20px', zIndex: 99 }} />
        </>
      )}
      <Paper sx={{ padding: '1rem' }}>
        <Typography sx={{
          fontWeight: '900',
          fontSize: '1.5rem',
        }}>{'Cancelamentos do dia ' + dayjs(date).format('DD/MM/YYYY')}</Typography>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <MultiFilter fields={filterableFields} data={cancelments} customSrcs={customSrcs} setRows={setFilteredData} />

        <BetterTable columns={[
            {field: 'name', headerName: 'Nome', width: 0.2 * 1132},
            {field: 'cancelment1', headerName: 'Cancelamento 1', width: 0.2 * 1132},
            {field: 'cancelment2', headerName: 'Cancelamento 2', width: 0.2 * 1132},
            {field: 'cancelment_not_done', headerName: 'Cancelamento não realizado', width: 0.2 * 1132},
            {field: 'total', headerName: 'Total', width: 0.2 * 1132},
          ]}
          rows={totals}
          rowHeight={40}
        />

        <Divider sx={{ mt: 5, mb: 5 }} />

        <BetterTable rowHeight={41}
        columns={[
          {
            headerName: 'Data',
            field: 'date',
            width: 0.2 * 1166,
            renderCell: (params) => dayjs(params.value).format('DD/MM/YYYY'),
          },
          {
            headerName: 'Rota',
            field: 'cidade',
            width: 0.2 * 1166,
            renderCell: (params) => {
              const route = routes.find((route) => route.number == params.value);
              return route ? route.name : '';
            }
          },
          {
            headerName: 'Entregador',
            field: 'entregador',
            width: 0.3 * 1166,
            renderCell: (params) => {
              const user = users.find((user) => user.id == params.value);
              return user ? user.name : '';
            }
          },
          {
            headerName: 'Tabelião',
            field: 'tabeliao',
            width: 0.2 * 1166,
          },
          {
            haderName: 'Status',
            field: 'status',
            width: 0.1 * 1166,
          }
        ]} rows={filteredData} />
      </Paper>
    </Page>
  );
};
import {
  Dialog, DialogContent, TextField, FormControl, InputLabel, Select, MenuItem,
  DialogActions, Button, DialogTitle, FormGroup
} from "@mui/material";
import {StyledTextareaAutosize} from "./StyledTextareaAutosize"
import {ErrorBox} from "./ErrorBox";
import {useNavigate} from "react-router-dom";
import {useCallback, useState} from "react";
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { apiCallRaw } from "../services/apiCall";
import { LoadingIndicator } from "./LoadingIndicator";
import 'dayjs/locale/pt-br';

export const EditFailure = ({doneCb, failure, deliveryMen}) => {
  const [type, setType] = useState(failure?.type || '');
  const [deliveryMan, setDeliveryMan] = useState(failure?.delivery_man_id || '');
  const [date, setDate] = useState(dayjs(failure.date.slice(0, 10), 'YYYY-MM-DD'));
  const [description, setDescription] = useState(failure.text || '');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  console.log(date);

  const updateFailure = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiCallRaw('failures', 'put', navigate, {
        delivery_man_id: deliveryMan,
        date: date.format('DD/MM/YYYY'),
        type,
        text: description,
        id: failure.id,
      });

      if (res.ok) {
        doneCb(true);
        return;
      }

      setErrors(res.errors);
    } catch (ex) {
      console.error(ex);
      setErrors([ex.response.data.errors]);
    } finally {
      setLoading(false);
    }
  }, [deliveryMan, date, type, description]);

  const deleteFailure = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiCallRaw('failures', 'delete', navigate, {
        id: failure.id,
      });

      if (res.ok) {
        doneCb(true);
        return;
      }

      setErrors(res.errors);
    } catch (ex) {
      console.error(ex);
      setErrors([ex.response.data.errors]);
    } finally {
      setLoading(false);
    }
  });

  return (
    <>
      <Dialog open={true} onClose={() => doneCb(false)}>
        <DialogTitle>Editar Falha</DialogTitle>
        <DialogContent sx={{ minWidth: 500 }}>
          <TextField
            fullWidth
            label='Tipo'
            placeholder='Tipo'
            type='text'
            sx={{
              mt: 2,
              mb: 1,
            }}
            value={type}
            onChange={(e) => setType(e.target.value)}
          />
          <FormControl fullWidth
            sx={{
              mt: 1,
              mb: 1,
            }}
          >
            <InputLabel>Entregador</InputLabel>
            <Select
              fullWidth
              label='Entregador'
              placeholder='Entregador'
              value={deliveryMan}
              onChange={(e) => setDeliveryMan(e.target.value)}
            >
              {deliveryMen.map((dm, index) => (
                <MenuItem key={index} value={dm.id}>{dm.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <DatePicker
            label='Data'
            fullWidth
            sx={{
              mt: 1,
              mb: 1,
              width: '100%'
            }}
            value={date}
            onChange={(newDate) => setDate(newDate)}
          />

          <StyledTextareaAutosize label='Descrição' placeholder='Descrição'
            value={description} onChange={e => setDescription(e.target.value)} />

          {errors.length > 0 && (
            <FormGroup sx={{ mt: 2 }}>
              <ErrorBox errors={errors} />
            </FormGroup>
          )}
        </DialogContent>

          <DialogActions>
            <Button color="error" variant="contained" sx={{ marginRight: 'auto' }} onClick={() => deleteFailure()}>Apagar</Button>
            <Button onClick={() => doneCb(false)}>Cancelar</Button>
            <Button onClick={() => updateFailure()} variant='contained'>Salvar</Button>
          </DialogActions>
        </Dialog>
      {loading && <LoadingIndicator />}
    </>
  )
};
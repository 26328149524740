import {
  Dialog, DialogContent, TextField, FormControl, InputLabel, Select, MenuItem,
  DialogActions, Button, DialogTitle, FormGroup
} from "@mui/material";
import {StyledTextareaAutosize} from "./StyledTextareaAutosize"
import {ErrorBox} from "./ErrorBox";
import {useNavigate} from "react-router-dom";
import {useCallback, useState} from "react";
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { apiCallRaw } from "../services/apiCall";
import { LoadingIndicator } from "./LoadingIndicator";
import 'dayjs/locale/pt-br';

export const AddPayment = ({doneCb, notaries}) => {
  const [notary, setNotary] = useState('');
  const [qty, setQty] = useState(0);
  const [t0, setT0] = useState(dayjs());
  const [t1, setT1] = useState(dayjs());
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  const addPayment = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiCallRaw('payments', 'post', navigate, {
        t0: t0.format('DD/MM/YYYY'),
        t1: t1.format('DD/MM/YYYY'),
        notary,
        qty,
        type,
      });

      if (res.ok) {
        doneCb(true);
        return;
      }

      setErrors(res.errors);
    } catch (ex) {
      console.error(ex);
      setErrors([ex.response.data.errors]);
    } finally {
      setLoading(false);
    }
  }, [t0, t1, notary, type]);


  return (
    <>
      <Dialog open={true} onClose={() => doneCb(false)}>
        <DialogTitle>Adicionar Pagamento</DialogTitle>
        <DialogContent sx={{ minWidth: 500 }}>

          <FormControl fullWidth
            sx={{
              mt: 1,
              mb: 1,
            }}
          >
            <InputLabel>Cartório</InputLabel>
            <Select
              fullWidth
              label='Cartório'
              placeholder='Cartório'
              value={notary}
              onChange={(e) => setNotary(e.target.value)}
            >
              {notaries.map(n => (
                <MenuItem key={n.name} value={n.id}>{n.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label='Quantidade de títulos'
            placeholder='Quantidade de títulos'
            type='number'
            sx={{
              mt: 1,
              mb: 1,
              mr: '1%',
              width: '49%',
            }}
            value={qty}
            onChange={(e) => setQty(e.target.value)}
          />

          <FormControl
            sx={{
              mt: 1,
              mb: 1,
              ml: '1%',
              width: '49%',
            }}
          >
            <InputLabel>Tipo</InputLabel>
            <Select
              fullWidth
              label='Tipo'
              placeholder='Tipo'
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem value='protocolo'>Protocolo</MenuItem>
              <MenuItem value='cancelamento'>Cancelamento</MenuItem>
            </Select>
          </FormControl>


         <DatePicker
            label='Data inicial'
            sx={{
              mt: 1,
              mb: 1,
              mr: '1%',
              width: '49%'
            }}
            value={t0}
            onChange={(newDate) => setT0(newDate)}
          />
          <DatePicker
            label='Data final'
            sx={{
              mt: 1,
              mb: 1,
              ml: '1%',
              width: '49%'
            }}
            value={t1}
            onChange={(newDate) => setT1(newDate)}
          />

          {errors.length > 0 && (
            <FormGroup sx={{ mt: 2 }}>
              <ErrorBox errors={errors} />
            </FormGroup>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => doneCb(false)}>Cancelar</Button>
          <Button onClick={() => addPayment()} variant='contained'>Salvar</Button>
        </DialogActions>

      </Dialog>
      {loading && <LoadingIndicator />}
    </>
  )
};
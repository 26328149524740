import { DataGrid, ptBR } from '@mui/x-data-grid';


export const BetterTable = (props) => {
  const {
    columns,
    rows,
    rowHeight = 'auto',
  } = props;
  return (
    <DataGrid
      getRowHeight={() => rowHeight}
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 100 },
        },
      }}
      getRowClassName={(params) => {
        switch (params.row.color) {
        case 'green':
          return 'green-row';
        case 'blue':
          return 'blue-row';
        case 'orange':
          return 'orange-row';
        }
      }}
      pageSizeOptions={[5, 10, 20, 40, 80, 100]}
    />
  );

}
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, IconButton, Typography } from '@mui/material';
import {
  Article, Assessment, CloudUpload, ForkRight, Logout, Paid, PriorityHigh,
} from '@mui/icons-material';

export const Header = () => {
  const navigate = useNavigate();
  const path = window.location.pathname;
  const thisUser = JSON.parse(localStorage.getItem('user') || '{}');
  let pages;
  
  if (thisUser.type === 'admin') {
    pages = [
      ['Movimentos', '/app/movimentos', <CloudUpload />],
      ['Gestor De Falhas', '/app/falhas', <PriorityHigh />],
      ['Pagamentos', '/app/pagamentos', <Paid />],
      ['Rotas', '/app/rotas', <ForkRight />],
      ['Cadastros', '/app/cadastros', <Article />],
    ];
  } else {
    pages = [
      ['Movimentos', '/app/movimentos', <CloudUpload />],
      ['Gestor De Falhas', '/app/falhas', <PriorityHigh />],
    ];
  }

  const logout = async () => {
    localStorage.removeItem('token');
    navigate('/app/login');
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <AppBar position="static" sx={{ padding: '1rem' }}>
      <div className='container' style={{margin: '0 auto'}}>
        <Box sx={{ display: 'flex', alignItems: 'center'}}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/app/dashboard"
            sx={{
              mr: 8,
              fontFamily: 'Roboto',
              fontWeight: 900,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              fontSize: '1.5rem',
            }}
          >
            ValorLOG
          </Typography>
        
          {pages.map((page) => (
            <Link key={page[1]} to={page[1]}>
            <Button
              key={page[1]}
              sx={{
                my: 1,
                mr: 3,
                color: 'white',
                backgroundColor: page[1] === path ?
                                  'rgba(0, 0, 0, 0.3)' : 'inherit',
              }}
              onClick={() => navigateTo(page[1])}
              startIcon={page[2]}
            >
              {page[0]}
            </Button>
            </Link>
          ))}

          <IconButton
            size="large"
            aria-label="logout"
            color="inherit"
            onClick={logout}
            sx={{ ml: 'auto' }}
          >
            <Logout />
          </IconButton>
        </Box>
      </div>
    </AppBar>
  );
};
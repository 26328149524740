import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
} from '@mui/material';
import {
  ExpandMore,
  ForkRight,
  Map,
} from '@mui/icons-material';
import { Page } from './Page';
import { LoadingIndicator } from './LoadingIndicator';
import { AddRoute } from './AddRoute';
import { EditRoute } from './EditRoute';
import { DeleteRoute } from './DeleteRoute';
import { AddCepRange } from './AddCepRange';
import { EditCepRange } from './EditCepRange';
import { DeleteCepRange } from './DeleteCepRange';
import { BetterTable } from './BetterTable';
import { apiCall } from '../services/apiCall';

export const Delivery = () => {
  const [loading, setLoading] = useState(true);
  const [hasRoutes, setHasRoutes] = useState(false);
  const [hasCepRanges, setHasCepRanges] = useState(false);
  const [hasDeliveryMen, setHasDeliveryMen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [cepRanges, setCepRanges] = useState([]);
  const [deliveryMen, setDeliveryMen] = useState([]);
  const [addingNewRoute, setAddingNewRoute] = useState(false);
  const [editingRoute, setEditingRoute] = useState(false);
  const [deletingRoute, setDeletingRoute] = useState(false);
  const [addingCepRange, setAddingCepRange] = useState(false);
  const [deletingCepRange, setDeletingCepRange] = useState(false);
  const [editingCepRange, setEditingCepRange] = useState(false);
  const [filteredRanges, setFilteredRanges] = useState([]);

  const table = useMemo(() => {
    const w = 1166;

    const columns = [
      {field: 'neighborhood', headerName: 'Bairro', width: 0.3 * w},
      {field: 'range_start', headerName: 'CEP Inicial', width: 0.10 * w},
      {field: 'range_end', headerName: 'CEP Final', width: 0.10 * w},
      {field: 'delivery_man', headerName: 'Entregador', width: 0.175 * w},
      {field: 'route', headerName: 'Rota', width: 0.175 * w},
      {
        field: 'actions',
        headerName: 'Ações',
        width: 0.15 * w,
        align: 'right',
        sortable: false,
        renderCell: (data) => (
          <>
            <Button
              variant='outlined'
              color='error'
              onClick={() => setDeletingCepRange(data.row)}
            >
              Excluir
            </Button>
            <Button
              variant='outlined'
              sx={{
                ml: 1,
              }}
              onClick={() => setEditingCepRange(data.row)}
            >
              Editar
            </Button>
          </>
        ),
      },
    ];
    const rows = filteredRanges.map((cepRange) => ({
      id: cepRange.id,
      neighborhood: cepRange.neighborhood,
      range_start: cepRange.range_start,
      range_end: cepRange.range_end,
      route: routes.find(r => r.id == cepRange.route)?.name || '',
      delivery_man: deliveryMen.find(u => u.id == routes.find(r => r.id == cepRange.route)?.delivery_man)?.name || '',
      route_id: cepRange.route,
    }));

    return {columns, rows};
  }, [filteredRanges, routes]);

  const navigate = useNavigate();

  const getRoutes = async () => {
    const data = await apiCall('routes', 'GET', navigate);
    const sorted = data.sort((a, b) => +a.number - +b.number);
    setRoutes(sorted);
    setHasRoutes(true);
  };

  const getCepRanges = async () => {
    const data = await apiCall('cep-ranges', 'GET', navigate);
    setCepRanges(data);
    setFilteredRanges(data);
    setHasCepRanges(true);
  };

  const getDeliveryMen = async () => {
    const data = await apiCall('users', 'GET', navigate);
    setDeliveryMen(data.filter(user => 
      !user.roles.includes('admin') && !user.roles.includes('admin2')
    ));
    setHasDeliveryMen(true);
  };

  useEffect(() => {
    getRoutes();
    getCepRanges();
    getDeliveryMen();
  }, []);

  useEffect(() => {
    setLoading(!(hasRoutes && hasCepRanges && hasDeliveryMen));
  }, [hasRoutes, hasCepRanges, hasDeliveryMen]);

  return (
    <Page title='Rotas'>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{
                fontWeight: '900'
              }}>Rotas</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {routes.length === 0 ? (
                <Typography>Nenhuma rota cadastrada</Typography>
              ) : (
                <BetterTable 
                rowHeight={64}
                  columns={[
                    {field: 'number', headerName: 'Número', sortComparator: (v1, v2) => +v1 - +v2, width: 0.1 * 1166},
                    {field: 'name', headerName: 'Nome', width: 0.2 * 1166},
                    {field: 'delivery_man', headerName: 'Entregador', width: 0.2 * 1166},
                    {field: 'notes', headerName: 'Observações', width: 0.35 * 1166},
                    {
                      field: 'actions',
                      headerName: 'Ações',
                      width: 0.15 * 1166,
                      align: 'right',
                      sortable: false,
                      renderCell: (data) => (
                        <>
                          <Button
                            variant='outlined'
                            color='error'
                            onClick={() => setDeletingRoute(data.row)}
                          >
                            Excluir
                          </Button>
                          <Button
                            variant='outlined'
                            sx={{
                              ml: 1,
                            }}
                            onClick={() => setEditingRoute(data.row)}
                          >
                            Editar
                          </Button>
                        </>
                      ),
                    },
                  ]}
                  rows={routes.map((route) => ({
                    id: route.id,
                    number: route.number,
                    name: route.name,
                    delivery_man: deliveryMen.find(d => d.id == route.delivery_man)?.name || '',
                    delivery_man_id: route.delivery_man,
                    notes: route.notes,
                  }))}
                />
              )}
              <Button 
                startIcon={<ForkRight />}
                variant='contained'
                sx={{
                  mt: 2,
                }}
                onClick={() => setAddingNewRoute(true)}
              >
                Adicionar
              </Button>

            </AccordionDetails>
          </Accordion>

          {addingNewRoute && (
            <AddRoute deliveryMen={deliveryMen} doneCb={(reload) => {
              if (reload) {
                getRoutes();
              }
              setAddingNewRoute(false);
            }} />
          )}

          {editingRoute && (
            <EditRoute deliveryMen={deliveryMen} doneCb={(reload) => {
              if (reload) {
                getRoutes();
              }
              setEditingRoute(false);
            }} route={editingRoute} />
          )}

          {deletingRoute && (
            <DeleteRoute doneCb={(reload) => {
              if (reload) {
                getRoutes();
              }
              setDeletingRoute(false);
            }} route={deletingRoute} />
          )}

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{
                fontWeight: '900'
              }}>Faixas de CEP</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {cepRanges.length === 0 ? (
                <Typography>Nenhuma faixa de CEP cadastrada</Typography>
              ) : (
                <div style={{width: '100%'}}>
                  <BetterTable columns={table.columns} rows={table.rows} rowHeight={64}/>
                </div>
              )}
              <Button 
                startIcon={<Map />}
                variant='contained'
                sx={{
                  mt: 2,
                }}
                onClick={() => setAddingCepRange(true)}
              >
                Adicionar
              </Button>

            </AccordionDetails>
          </Accordion>

          {addingCepRange && (
            <AddCepRange doneCb={(reload) => {
              if (reload) {
                getCepRanges();
              }
              setAddingCepRange(false);
            }} routes={routes} />
          )}

          {deletingCepRange && (
            <DeleteCepRange doneCb={(reload) => {
              if (reload) {
                getCepRanges();
              }
              setDeletingCepRange(false);
            }} cepRange={deletingCepRange} />
          )}

          {editingCepRange && (
            <EditCepRange doneCb={(reload) => {
              if (reload) {
                getCepRanges();
              }
              setEditingCepRange(false);
            }} routes={routes} cepRange={editingCepRange} />
          )}
        </>
      )}
    </Page>
  );
};
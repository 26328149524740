import { useCallback, useState, useEffect } from 'react';
import { Page } from './Page';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { 
  AdminPanelSettings,
  LocalShipping,
  PersonAdd,
  DomainAdd,
} from '@mui/icons-material';
import { ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { apiCall } from '../services/apiCall';
import { AddUser } from './AddUser';
import { DeleteUser } from './DeleteUser';
import { EditUser } from './EditUser';
import { ViewUser } from './ViewUser';
import { AddNotary } from './AddNotary';
import { DeleteNotary } from './DeleteNotary';
import { EditNotary } from './EditNotary';
import { LoadingIndicator } from './LoadingIndicator';

export const Records = () => {
  const [users, setUsers] = useState([]);
  const [notaries, setNotaries] = useState([]);
  const [hasUsers, setHasUsers] = useState(false);
  const [hasNotaries, setHasNotaries] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deletedUser, setDeletedUser] = useState(false);
  const [editedUser, setEditedUser] = useState(false);
  const [addingNewUser, setAddingNewUser] = useState(false);
  const [viewingUser, setViewingUser] = useState(false);
  const [addingNewNotary, setAddingNewNotary] = useState(false);
  const [editedNotary, setEditedNotary] = useState(false);
  const [deletedNotary, setDeletedNotary] = useState(false);
  const thisUser = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const loadUsers = useCallback(async () => {
    try {
      const data = await apiCall('users-inactive', 'get', navigate);
      setUsers(data);
      setHasUsers(true);
    } catch (err) {
      console.log(err);
    }
  }, [navigate]);

  const loadNotaries = useCallback(async () => {
    try {
      const data = await apiCall('notaries', 'get', navigate);
      setNotaries(data);
      setHasNotaries(true);
    } catch (err) {
      console.log(err);
    }
  }, [navigate]);

  const addUserDoneCb = (ok) => {
    setAddingNewUser(false);

    if (ok) {
      setHasUsers(false);
      loadUsers();
    }
  };

  const deleteUserDoneCb = (ok) => {
    setDeletedUser(false);

    if (ok) {
      setHasUsers(false);
      loadUsers();
    }
  };

  const editUserDoneCb = (ok) => {
    setEditedUser(false);

    if (ok) {
      setHasUsers(false);
      loadUsers();
    }
  };

  useEffect(() => {
    (() => {
      if (hasUsers && hasNotaries) {
        setLoading(false);
      }
    })();
  }, [hasUsers, hasNotaries]);

  useEffect(() => {
    loadUsers();
    loadNotaries();
  }, [loadUsers, loadNotaries]);

  return (
    <Page title='Cadastros'>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{
                fontWeight: '900'
              }}>Usuários</Typography>
            </AccordionSummary>
            <AccordionDetails>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 900 }}>ID</TableCell>
                      <TableCell sx={{ fontWeight: 900 }}>Nome</TableCell>
                      <TableCell sx={{ fontWeight: 900 }}>Telefone</TableCell>
                      <TableCell sx={{ fontWeight: 900 }}>Tipo</TableCell>
                      <TableCell sx={{ fontWeight: 900 }}>Ativo</TableCell>
                      <TableCell sx={{ fontWeight: 900 }} align='right'>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow
                        key={user.id}
                      >
                        <TableCell>{user.id}</TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.phone}</TableCell>
                        <TableCell>
                          {user.roles.indexOf('admin') !== -1 ? (
                            <div><AdminPanelSettings sx={{verticalAlign: 'middle'}} /> Administrador</div>
                          ) : user.roles.indexOf('admin2') !== -1 ? (
                            <div><AdminPanelSettings sx={{verticalAlign: 'middle'}} /> Administrador 2</div>
                          ) : (
                            <div><LocalShipping sx={{verticalAlign: 'middle'}} /> Entregador</div>
                          )}
                        </TableCell>
                        <TableCell>{user.is_active ? 'Ativo' : 'Inativo'}</TableCell>
                          <TableCell align='right'>
                            {thisUser.type === 'admin2' && user.type === 'admin' ? (
                              <></>
                            ) : (
                              <>
                                <Button
                                  variant='outlined'
                                  color='error'
                                  onClick={() => setDeletedUser(user)}
                                >
                                  Excluir
                                </Button>
                                <Button
                                  variant='outlined'
                                  sx={{
                                    ml: 1,
                                  }}
                                  onClick={() => setEditedUser(user)}
                                >
                                  Editar
                                </Button>
                              </>
                            )}
                          <Button
                            color='info'
                            sx={{
                              ml: 1,
                            }}
                            onClick={() => setViewingUser(user)}
                          >
                            Visualizar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Button
                startIcon={<PersonAdd />}
                variant='contained'
                sx={{
                  mt: 2,
                }}
                onClick={() => setAddingNewUser(true)}
              >
                Adicionar
              </Button>
              
            </AccordionDetails>
          </Accordion>

          {deletedUser && (
            <DeleteUser user={deletedUser} doneCb={deleteUserDoneCb} />
          )}

          {addingNewUser && (
            <AddUser doneCb={addUserDoneCb} />
          )}

          {editedUser && (
            <EditUser user={editedUser} doneCb={editUserDoneCb} />
          )}

          {viewingUser && (
            <ViewUser user={viewingUser} doneCb={() => setViewingUser(false)} />
          )}

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{
                fontWeight: '900'
              }}>Cartórios</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {notaries.length === 0 ? (
                <Typography>Nenhum cartório cadastrado.</Typography>
              ) : (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 800 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 900 }}>Número</TableCell>
                        <TableCell sx={{ fontWeight: 900 }}>Nome</TableCell>
                        <TableCell sx={{ fontWeight: 900 }}>CNPJ</TableCell>
                        <TableCell sx={{ fontWeight: 900 }}>Endereço</TableCell>
                        <TableCell sx={{ fontWeight: 900 }}>Telefone</TableCell>
                        <TableCell sx={{ fontWeight: 900 }}>E-mail</TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align='right'>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {notaries.map((notary) => (
                        <TableRow
                          key={notary.id}
                        >
                          <TableCell>{notary.number}</TableCell>
                          <TableCell>{notary.name}</TableCell>
                          <TableCell>{notary.cnpj}</TableCell>
                          <TableCell>{notary.address}</TableCell>
                          <TableCell>{notary.phone}</TableCell>
                          <TableCell>{notary.email}</TableCell>
                          <TableCell align='right'>
                            <Button
                              variant='outlined'
                              color='error'
                              onClick={() => setDeletedNotary(notary)}
                            >
                              Excluir
                            </Button>
                            <Button
                              variant='outlined'
                              sx={{
                                ml: 1,
                              }}
                              onClick={() => setEditedNotary(notary)}
                            >
                              Editar
                            </Button>
                          </TableCell>
                        </TableRow>))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <Button 
                startIcon={<DomainAdd />}
                variant='contained'
                sx={{
                  mt: 2,
                }}
                onClick={() => setAddingNewNotary(true)}
              >
                Adicionar
              </Button>
            </AccordionDetails>
          </Accordion>

          {addingNewNotary && (
            <AddNotary doneCb={() => {
              setAddingNewNotary(false);
              setHasNotaries(false);
              loadNotaries();
            }} />
          )}

          {deletedNotary && (
            <DeleteNotary notary={deletedNotary} doneCb={() => {
              setDeletedNotary(false);
              setHasNotaries(false);
              loadNotaries();
            }} />
          )}

          {editedNotary && (
            <EditNotary notary={editedNotary} doneCb={() => {
              setEditedNotary(false);
              setHasNotaries(false);
              loadNotaries();
            }} />
          )}
        </>
      )}
    </Page>
  );
};
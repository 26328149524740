import { useCallback, useEffect, useState } from "react";
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { debounce } from "lodash";

export const MultiFilter = ({fields, data, customSrcs, setRows = () => {}}) => {
  const [filters, setFilters] = useState([]);
  const [filtersField, setFiltersField] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [params, setParams] = useState(useLocation().search.slice(1).split('&').map((param) => param.split('=')));

  useEffect(() => {
    const argFilters = [];
    const argFiltersField = [];

    for (let i = 0; i < params.length; i++) {
      if (params[i].length !== 2) {
        continue;
      }
      argFiltersField.push(decodeURIComponent(params[i][0]));
      argFilters.push(decodeURIComponent(params[i][1]));
    }

    setFilters(argFilters);
    setFiltersField(argFiltersField);
  }, []);

  const filter = useCallback(debounce(async () => {
    if (filters.length === 0) {
      setRows(data);
      return;
    }

    if (filtersField.filter((f, i) => f.split('*')[1] === '').length > 0
      || filtersField.filter((f, i) => f.split('*')[1] === 'undefined').length > 0
    ) {
      console.log('invalid filter')
      return;
    }

    const operators = {
      '%=%': (a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.toLowerCase().includes(b.toLowerCase());
      },
      '=': (a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a == b;
      },
      '!=': (a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a != b;
      }
    };

    const newRows = data.filter(row => {
      return filtersField.map((f, i) => {
        const rawParts = f.split('*');
        let parts = [
          rawParts[0],
          rawParts[1],
        ];
        if (customSrcs.hasOwnProperty(parts[0])) {
          return customSrcs[parts[0]](row, filters[i], parts[1]);
        }

        return operators[parts[1]](row[parts[0]], filters[i]);
      }).reduce((a, b) => a && b, true);
    });

    setRows(newRows);
  }, 1000), [data, customSrcs, filters, filtersField]);

  useEffect(() => {
    if (filters.length !== filtersField.length
      || !filtersField[filtersField.length - 1] 
      || filtersField.filter((f,i) => f.split('*')[1] === '').length > 0
      || filtersField.filter((f,i) => f.split('*')[1] === 'undefined').length > 0
    ) {
      if (filters.length === 0) {
        setParams([]);
        window.history.replaceState(null, null, `?`);
        filter();
      }
      return;
    }

    const newParams = [];
    for (let i = 0; i < filters.length; i++) {
      newParams.push(`${encodeURIComponent(filtersField[i])}=${encodeURIComponent(filters[i])}`);
    }

    if (newParams.length === 0) {
      return;
    }

    setParams(...newParams);
    window.history.replaceState(null, null, `?${newParams.join('&')}`);

    filter();
  }, [filters, filtersField]);

  useEffect(() => {
    filter();
  }, [data]);

  return (
    <>
      <Button variant="contained" startIcon={<Search />} onClick={() => {
        setFilterCount(filterCount + 1);
        setFilters([...filters, '']);
        setFiltersField([...filtersField, '']);
      }}>Novo Filtro</Button>

      {filters.map((filter, index) => (
        <div key={index}>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Button
            sx={{
              width: '11%',
              height: '56px',
              verticalAlign: 'top',
            }}
            variant="contained" startIcon={<Close />} onClick={() => {
              const newFilters = filters.filter((f, i) => i !== index);
              const newFiltersField = filtersField.filter((f, i) => i !== index);
              setFilters(newFilters);
              setFiltersField(newFiltersField);
              setFilterCount(filterCount - 1);
            }}>Remover</Button>

          <TextField sx={{
            ml: '1%',
            width: '50%',
          }} label='Expressão' variant='outlined' value={filter} onChange={(e) => {
            const newFilters = filters.map((f, i) => i === index ? e.target.value : f);
            setFilters(newFilters);
          }} />

          <FormControl
            sx={{
              mt: 0,
              mb: 0,
              ml: '1%',
              width: '27%',
              verticalAlign: 'top',
              display: 'inline-block',
            }}
          >
            <InputLabel>Campo</InputLabel>
            <Select
              fullWidth
              label='Campo'
              placeholder='Campo'
              value={filtersField[index].split('*')[0] || ''}
              onChange={(e) => {
                const parts = filtersField[index].split('*');
                filtersField[index] = e.target.value + '*' + parts[1];
                setFiltersField([...filtersField]);
              }}
            >
              <MenuItem key='' value=''>Escolha</MenuItem>
              {fields.map((field, index) => (
                <MenuItem key={field.value} value={field.value}>{field.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              mt: 0,
              mb: 0,
              ml: '1%',
              width: '9%',
              verticalAlign: 'top',
              display: 'inline-block',
            }}
          >
            <InputLabel>Operador</InputLabel>
            <Select
              fullWidth
              label='Operador'
              placeholder='Operador'
              value={filtersField[index].split('*')[1] || ''}
              onChange={(e) => {
                const parts = filtersField[index].split('*');
                filtersField[index] = parts[0] + '*' + e.target.value;
                setFiltersField([...filtersField]);
              }}
            >
              <MenuItem key='' value=''>Escolha</MenuItem>
              {fields.find(f => f.value === filtersField[index].split('*')[0])?.operators.map((operator, index) => (
                <MenuItem key={operator} value={operator}>{operator}</MenuItem>
              ))}
            </Select>
          </FormControl>



        </div>
      ))}

      <Divider sx={{ mt: 2, mb: 2 }} />
    </>
  );
};
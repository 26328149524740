const Colors = {
  green: {
    bg: '#c6efcd',
    fg: '#095f10'
  },
  blue: {
    bg: '#d8e9f9',
    fg: '#285f84'
  },
  orange: {
    bg: '#feeb9c',
    fg: '#8e5b0a'
  }
};

export { Colors };
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import dayjs from 'dayjs';
import { ImagePopUp } from './ImagePopUp';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { apiCallRaw } from '../services/apiCall';
import { useNavigate } from 'react-router-dom';
import 'dayjs/locale/br';

export const ViewMovement = ({ doneCb, users, movement }) => {
  const [imageShown, setImageShown] = useState(false);

  const [deliveryStatus1, setDeliveryStatus1] = useState(movement.delivery_status1 || '');
  const [deliveryStatusEditing1, setDeliveryStatusEditing] = useState(false);
  const [obs1, setObs1] = useState(movement.obs1 || '');
  const [obs1Editing, setObs1Editing] = useState(false);

  const [deliveryStatus2, setDeliveryStatus2] = useState(movement.delivery_status2 || '');
  const [deliveryStatusEditing2, setDeliveryStatusEditing2] = useState(false);
  const [obs2, setObs2] = useState(movement.obs2 || '');
  const [obs2Editing, setObs2Editing] = useState(false);

  const [type, setType] = useState(movement.type || '');
  const [typeEditing, setTypeEditing] = useState(false);

  const [withDeliveryMan, setWithDeliveryMan] = useState(movement.on_the_go ? 'Sim' : 'Não');
  const [withDeliveryManEditing, setWithDeliveryManEditing] = useState(false);

  const [firstCall, setFirstCall] = useState(true);

  const [canSave, setCanSave] = useState(false);
  const [saving, setSaving] = useState(false);

  const [goToSecondVisit, setGoToSecondVisit] = useState(movement.go_to_second_visit);
  const [goToSecondVisitEditing, setGoToSecondVisitEditing] = useState(false);

  const navigate = useNavigate();

  let statuses = [
    'Entregue',
    'Não atendido',
    'Mudou-se',
    'Endereço não localizado',
    'Não existe o número',
    'Desconhecido',
    'Recusado',
    'Fechado',
    'Outros',
    'Retornar',
  ];

  const saveMovement = useCallback(async () => {
    const body = {
      deliveryStatus1: deliveryStatus1 === 'empty' ? '' : deliveryStatus1,
      obs1,
      deliveryStatus2: deliveryStatus2 === 'empty' ? '' : deliveryStatus2,
      obs2,
      type,
      withDeliveryMan,
      id: movement.id,
      goToSecondVisit,
    };

    setSaving(true);

    try {
      const res = await apiCallRaw(`protocols/${encodeURIComponent(movement.protocolo)}`, 'PUT', navigate, body, {
        'Content-Type': 'application/json',
      });

      movement.delivery_status1 = deliveryStatus1;
      movement.obs1 = obs1;
      movement.delivery_status2 = deliveryStatus2;
      movement.obs2 = obs2;
      movement.type = type;
      movement.on_the_go = withDeliveryMan === 'Sim';
      movement.waiting_close = withDeliveryMan !== 'Sim';
      movement.last_edited_by = JSON.parse(localStorage.getItem('user') || '{}')?.id;
      movement.situation = type === 'visita1' ?
        (deliveryStatus1 === 'empty' ? 'Em aberto' : (goToSecondVisit ? 'Em rota' : 'Concluído'))
        : (deliveryStatus2 === 'empty' ? 'Em aberto' : 'Concluído');
      movement.go_to_second_visit = goToSecondVisit ? true : false;

      setCanSave(false);
      toast.success('Movimento salvo com sucesso', {
        autoClose: 2000,
        position: 'bottom-left',
      });
    } catch (err) {
      setDeliveryStatus1(movement.delivery_status1);
      setObs1(movement.obs1);
      setDeliveryStatus2(movement.delivery_status2);
      setObs2(movement.obs2);
      setType(movement.type);
      setWithDeliveryMan(movement.on_the_go ? 'Sim' : 'Não');
      setGoToSecondVisit(movement.go_to_second_visit);

      if (err.response?.data?.movement) {
        const keys = ['deliveryStatus1', 'obs1', 'deliveryStatus2', 'obs2', 'type'];
        for (const key of keys) {
          if (err.response.data?.movement?.hasOwnProperty(key)) {
            eval(`set${ key.slice(0,1).toUpperCase() + key.slice(1) }('${err.response.data.movement[key]}')`);
          }
        }
      }

      for (let error of err.response.data.errors) {
        toast.error(error, {
          autoClose: 10000,
          position: 'bottom-left',
        });
      }
    } finally {
      setSaving(false);
    }
  }, [
    deliveryStatus1, deliveryStatus2, obs1, obs2, type, goToSecondVisit, withDeliveryMan,
    movement, navigate
  ]);

  useEffect(() => {
    if (firstCall) {
      setFirstCall(false);
      return;
    }
    if (!deliveryStatusEditing1
      && !deliveryStatusEditing2
      && !obs1Editing
      && !obs2Editing
      && !typeEditing
      && !withDeliveryManEditing) {
      setCanSave(true);
    }
  }, [
    deliveryStatusEditing1, deliveryStatusEditing2, obs1Editing, obs2Editing,
    typeEditing, withDeliveryManEditing, goToSecondVisit
  ]);

  return (
    <>
    {imageShown && <ImagePopUp image={imageShown} onClose={() => {setImageShown(false)}} />}
    <Dialog open={true} onClose={() => doneCb(false)}>
      <DialogTitle>Visualizar movimento</DialogTitle>
      <DialogContent sx={{
        minWidth: 500,
      }}>
         <TableContainer>
          <Table>
            <TableBody>
                <TableRow key={movement.id}>
                  <TableCell>ID</TableCell>
                  <TableCell>{movement.id}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.date'}>
                  <TableCell>Data</TableCell>
                  <TableCell>{dayjs(movement.date).format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.tabeliao'}>
                  <TableCell>Tabelião</TableCell>
                  <TableCell>{movement.tabeliao}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.protocolo'}>
                  <TableCell>Protocolo</TableCell>
                  <TableCell>{movement.protocolo}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.nome'}>
                  <TableCell>Nome</TableCell>
                  <TableCell>{movement.nome}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.cpf_cnpj'}>
                  <TableCell>CPF/CNPJ</TableCell>
                  <TableCell>{movement.cpf_cnpj}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.cidade'}>
                  <TableCell>Cidade</TableCell>
                  <TableCell>{movement.cidade}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.cep'}>
                  <TableCell>CEP</TableCell>
                  <TableCell>{movement.cep}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.endereco'}>
                  <TableCell>Endereço</TableCell>
                  <TableCell>{movement.endereco}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.go_to_second_visit'}>
                  <TableCell>Ir para a visita 2</TableCell>
                  {!goToSecondVisitEditing ? (
                    <TableCell
                      onDoubleClick={() => setGoToSecondVisitEditing(true)}
                    >
                      {goToSecondVisit ? 'Sim' : 'Não'}
                    </TableCell>
                  ) : (
                    <TableCell>
                        <FormControl
                          sx={{
                            mt: 0,
                            mb: 0,
                            width: '100%',
                            display: 'inline-block',
                          }}
                        >
                          <InputLabel>Ir para a visita 2</InputLabel>
                          <Select
                            fullWidth
                            label='Ir para a visita 2'
                            placeholder='Ir para a visita 2'
                            value={goToSecondVisit ? 'sim' : 'nao'}
                            onChange={(e) => {
                              setGoToSecondVisit(e.target.value == 'sim' ? true : false);
                            }}
                            onMouseLeave={(e) => {
                              setGoToSecondVisitEditing(false);
                            }}
                          >
                            <MenuItem key={'sim'} value={'sim'}>Sim</MenuItem>
                            <MenuItem key={'nao'} value={'nao'}>Não</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                  )}
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.status'}>
                  <TableCell>Status</TableCell>
                  <TableCell>
                    {(movement.type === 'visita2' && movement.delivery_status2 !== 'empty' && movement.delivery_status2)
                    || (movement.type === 'visita1' && movement.delivery_status1 !== 'empty' && movement.delivery_status1)}
                  </TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.type'}>
                  <TableCell sx={{whiteSpace: 'nowrap', width: '1%'}}>Tipo</TableCell>

                  {!typeEditing ? (
                    <TableCell
                      onDoubleClick={() => setTypeEditing(true)}
                    >{type}</TableCell>
                  ) : (
                    <TableCell>
                      <FormControl
                        sx={{
                          mt: 0,
                          mb: 0,
                          width: '100%',
                          display: 'inline-block',
                        }}
                      >
                        <InputLabel>Tipo</InputLabel>
                        <Select
                          fullWidth
                          label='Tipo'
                          placeholder='Tipo'
                          value={type}
                          onChange={(e) => {
                            setType(e.target.value);
                            setTypeEditing(false);
                          }}
                          onMouseLeave={(e) => {
                            setTypeEditing(false);
                          }}
                          >
                            <MenuItem key={'visita1'} value={'visita1'}>Visita 1</MenuItem>
                            <MenuItem key={'visita2'} value={'visita2'}>Visita 2</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                  )}

                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.route'}>
                  <TableCell>Rota</TableCell>
                  <TableCell>{movement.route}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.waiting_close'}>
                  <TableCell>Esperando fechamento</TableCell>
                  <TableCell>{(movement.waiting_close && 'Sim') || 'Não'}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.available_to_app'}>
                  <TableCell>Disponível para o app</TableCell>
                  <TableCell>{(movement.available_to_app && 'Sim') || 'Não'}</TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.on_the_go'}>
                  <TableCell>Com o entregador</TableCell>

                  {!withDeliveryManEditing ? (
                    <TableCell onDoubleClick={() => setWithDeliveryManEditing(true)}>
                      {withDeliveryMan}
                    </TableCell>
                  ) : (
                    <TableCell>
                      <FormControl
                        sx={{
                          mt: 0,
                          mb: 0,
                          ml: 0,
                          mr: 0,
                          width: '100%',
                          display: 'inline-block',
                        }}
                      >
                        <InputLabel>Com o entregador</InputLabel>
                        <Select
                          fullWidth
                          label='Com o entregador'
                          value={withDeliveryMan}
                          onChange={(e) => {
                            setWithDeliveryMan(e.target.value);
                            setWithDeliveryManEditing(false);
                          }}
                          onMouseLeave={(e) => {
                            setWithDeliveryManEditing(false);
                          }}
                        >
                          <MenuItem key={'Não'} value={'Não'}>Não</MenuItem>
                          <MenuItem key={'Sim'} value={'Sim'}>Sim</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  )}

                </TableRow>

                <TableRow key={'entregador'}>
                  <TableCell>
                    Entregador
                  </TableCell>
                  <TableCell>
                    {users.find(u => u.id == movement.delivery_man_id)?.name}
                  </TableCell>
                </TableRow>

                <TableRow key={movement.id + '-' + 'movement.last_edited_by'}>
                  <TableCell>Última edição</TableCell>
                  <TableCell>{users.find(u => u.id == movement.last_edited_by)?.name + ' - ID ' + movement.last_edited_by}</TableCell>
                </TableRow>

                <TableRow key={'entrega1'}>
                  <TableCell colSpan={2}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Table sx={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
                        <TableBody>
                          <TableRow key={movement.id + '-' + 'movement.delivery_status1'}>
                            <TableCell sx={{whiteSpace: 'nowrap', width: '1%'}}>Status de entrega 1</TableCell>

                            {!deliveryStatusEditing1 ? (
                              <TableCell sx={{}} onDoubleClick={() => {
                                setDeliveryStatusEditing(true);
                              }}>
                                {deliveryStatus1 === 'empty' ? '' : deliveryStatus1}
                              </TableCell>
                            ) : (
                              <TableCell>
                                <FormControl
                                  sx={{
                                    mt: 0,
                                    mb: 0,
                                    ml: 0,
                                    mr: 0,
                                    width: '100%',
                                    display: 'inline-block',
                                  }}
                                >
                                  <InputLabel>Status</InputLabel>
                                  <Select
                                    fullWidth
                                    label='status'
                                    placeholder='status'
                                    value={deliveryStatus1 === 'inicial' ? '' : deliveryStatus1}
                                    onChange={(e) => {
                                      setDeliveryStatus1(e.target.value);
                                      setDeliveryStatusEditing(false);
                                    }}
                                    onMouseLeave={(e) => {
                                      setDeliveryStatusEditing(false);
                                    }}
                                  >
                                    <MenuItem key={'empty'} value={'empty'}>Em branco</MenuItem>
                                    {statuses.map((status, index) => (
                                      <MenuItem key={index} value={status}>{status}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </TableCell>
                            )}

                          </TableRow>
                          <TableRow key={movement.id + '-' + 'movement.obs1'}>
                            <TableCell sx={{whiteSpace: 'nowrap', width: '1%'}}>Observação 1</TableCell>

                            {!obs1Editing ? (
                              <TableCell onDoubleClick={() => setObs1Editing(true)}>{obs1 || ''}</TableCell>
                            ) : (
                                <TableCell>
                                  <TextField
                                    label='Observação 1'
                                    placeholder='Observação 1'
                                    type='text'
                                    fullWidth
                                    value={obs1}
                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        setObs1Editing(false);
                                      }
                                    }}
                                    onMouseLeave={(e) => {
                                      setObs1Editing(false);
                                    }}
                                    onChange={(e) => setObs1(e.target.value)}
                                  /> 
                              </TableCell>
                            )}

                          </TableRow>
                          <TableRow key={movement.id + '-' + 'movement.images1'}>
                            <TableCell>Imagens 1</TableCell>
                            <TableCell>
                              {movement.json1?.photos &&
                                movement.json1.photos.map((image, index) => (
                                  <img key={index} src={'data:image/png;base64,' + image} width={64} height={64}
                                    onClick={() => setImageShown(image)}
                                    style={{ cursor: 'pointer', marginRight: 8 }}
                                  />
                                ))}
                            </TableCell>
                          </TableRow>
                          <TableRow key={movement.id + '-' + 'movement.dateTime1'}>
                            <TableCell>Data e hora</TableCell>
                            <TableCell>{
                              movement.json1?.dateTime && dayjs(movement.json1.dateTime).format('HH:mm:ss DD/MM/YYYY')
                            }</TableCell>
                          </TableRow>

                          <TableRow key={movement.id + '-' + 'movement.gps1'}>
                            <TableCell>GPS</TableCell>
                            <TableCell>
                              { movement.json1?.gps?.coords &&  (
                                <a target="_blank" href={`https://maps.google.com/?q=${movement.json1.gps.coords.latitude},${movement.json1.gps.coords.longitude}`}>
                                  Ver localização
                                </a>
                              )}
                              </TableCell>
                          </TableRow>



                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow key={'entrega2'}>
                  <TableCell colSpan={2}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Table sx={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
                        <TableBody>

                          <TableRow key={movement.id + '-' + 'movement.delivery_status2'}>
                            <TableCell sx={{ whiteSpace: 'nowrap', width: '1%' }}>Status de entrega 2</TableCell>

                            {!deliveryStatusEditing2 ? (
                              <TableCell sx={{}} onDoubleClick={() => {
                                setDeliveryStatusEditing2(true);
                              }}>
                                {deliveryStatus2 === 'empty' ? '' : deliveryStatus2}
                              </TableCell>
                            ) : (
                              <TableCell>
                                <FormControl
                                  sx={{
                                    mt: 0,
                                    mb: 0,
                                    width: '100%',
                                    display: 'inline-block',
                                  }}
                                >
                                  <InputLabel>Status</InputLabel>
                                  <Select
                                    fullWidth
                                    label='status'
                                    placeholder='status'
                                    value={deliveryStatus2 === 'inicial' ? '' : deliveryStatus2}
                                    onChange={(e) => {
                                      setDeliveryStatus2(e.target.value);
                                      setDeliveryStatusEditing2(false);
                                    }}
                                    onMouseLeave={(e) => {
                                      setDeliveryStatusEditing2(false);
                                    }}
                                  >
                                    <MenuItem key={'empty'} value={'empty'}>Em branco</MenuItem>
                                    {statuses.map((status, index) => (
                                      <MenuItem key={index} value={status}>{status}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </TableCell>
                            )}

                          </TableRow>

                          <TableRow key={movement.id + '-' + 'movement.obs2'}>
                            <TableCell sx={{whiteSpace: 'nowrap', width: '1%'}}>Observação 2</TableCell>

                            {!obs2Editing ? (
                              <TableCell onDoubleClick={() => setObs2Editing(true)}>{obs2 || ''}</TableCell>
                            ) : (
                                <TableCell>
                                  <TextField
                                    label='Observação 2'
                                    placeholder='Observação 2'
                                    type='text'
                                    fullWidth
                                    value={obs2}
                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        setObs2Editing(false);
                                      }
                                    }}
                                    onMouseLeave={(e) => {
                                      setObs2Editing(false);
                                    }}
                                    onChange={(e) => setObs2(e.target.value)}
                                  /> 
                              </TableCell>
                            )}

                          </TableRow>

                          <TableRow key={movement.id + '-' + 'movement.images2'}>
                            <TableCell>Imagens 2</TableCell>
                            <TableCell>
                              {movement.json2?.photos &&
                                movement.json2.photos.map((image, index) => (
                                  <img key={index} src={'data:image/png;base64,' + image} width={64} height={64}
                                    onClick={() => setImageShown(image)}
                                    style={{ cursor: 'pointer', marginRight: 8 }}
                                  />
                                ))}
                            </TableCell>
                          </TableRow>

                          <TableRow key={movement.id + '-' + 'movement.gps2'}>
                            <TableCell>GPS</TableCell>
                            <TableCell>
                              { movement.json2?.gps &&  (
                                <a target="_blank" href={`https://maps.google.com/?q=${movement.json2.gps.coords.latitude},${movement.json2.gps.coords.longitude}`}>
                                  Ver localização
                                </a>
                              )}
                              </TableCell>
                          </TableRow>

                          <TableRow key={movement.id + '-' + 'movement.dateTime2'}>
                            <TableCell>Data e hora</TableCell>
                            <TableCell>{
                              movement.json2?.dateTime && dayjs(movement.json2.dateTime).format('HH:mm:ss DD/MM/YYYY')
                            }</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => saveMovement()} disabled={!canSave || saving}>Salvar</Button>
        <Button onClick={() => doneCb(false)} disabled={saving}>Fechar</Button>
      </DialogActions>
    </Dialog>
  </>
  );
};
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import * as yup from 'yup';
import { ErrorBox } from './ErrorBox';
import axios from 'axios';
import Box from '@mui/material/Box';
import { Navigate, useNavigate } from 'react-router-dom';

import './Login.css';

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);

    const schema = yup.object().shape({
      id: yup
            .string()
            .required('ID é obrigatório e deve ser um número'),
      password: yup.string()
                  .min(8, 'A senha deve ter pelo menos 8 caracteres')
                  .required('Senha é obrigatória'),
    });

    try {
      await schema.validate({
        id,
        password,
      }, {abortEarly: false});

      try {
        const response = await axios.post('/app/api/session', {
          id: +id,
          password,
        });

        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));

        navigate('/app/movimentos');
      } catch (err) {
        if (err.response?.data?.errors) {
          throw {errors: err.response.data.errors};
        }

        throw {errors: ['Erro de rede']};
      }
    } catch (err) {
      setErrors(err.errors);
    } finally {
      setLoading(false);
    }
  };

  if (localStorage.getItem('token') && localStorage.getItem('user')) {
    return <Navigate to='/app/movimentos' />;
  }

  return <div className='Login'>
    <Box width='500px'>
      <h1>Entrar</h1>
      <div className='entry'>
        <TextField 
          fullWidth 
          label='ID'
          placeholder='ID' 
          type='number'
          value={id}
          onChange={e => setId(e.target.value)} 
        />
      </div>
      <div className='entry'>
        <TextField 
          fullWidth 
          label='Senha'
          placeholder='Senha' 
          type='password'
          value={password}
          onChange={e => setPassword(e.target.value)} 
        />
      </div>
      <div className='entry'>
        <LoadingButton
          loading={loading}
          variant='contained'
          color='primary'
          size='large'
          fullWidth
          onClick={handleLogin}>
            Entrar
        </LoadingButton>
      </div>
      {errors.length > 0 ? 
        <div className='entry'>
          <ErrorBox errors={errors} />
        </div>
        : null
      }
    </Box>
  </div>;
};

import { Backdrop, CircularProgress, Paper } from "@mui/material";

export const LoadingIndicator = () => {
  return (
    <>
      <Backdrop open={true} sx={{zIndex: 9998}}/>
      <Paper sx={{
        display: 'flex',
        position: 'fixed',
        zIndex: 9999,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '2rem',
      }}>
        <CircularProgress sx={{margin: 'auto' }}/>
      </Paper>
    </>
  );
};
import { Header } from './Header';

export const Page = ({ children, title }) => {
  document.title = title + ' | ValorLOG';
  return (
    <>
      <Header />
      <div className='container'>
        {children}
      </div>
    </>
  )
};
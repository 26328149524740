import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Page } from './Page';
import { Close, FileDownload, More, Search, SendToMobile } from '@mui/icons-material';
import {
  Backdrop, Box, Button, CircularProgress, Divider, FormControl, Select,
  IconButton, InputLabel, Paper, TextField, Typography, MenuItem
} from '@mui/material';
import { debounce, get } from 'lodash';
import dayjs from 'dayjs';
import { apiCall, apiCallRaw } from '../services/apiCall';
import { BetterTable } from './BetterTable';
import { ViewMovement } from './ViewMovement';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { MultiFilter } from './MultiFilter';
import { ResendProtocols } from './ResendProtocols';
import { Colors } from '../Colors';

export const SingleMovement = () => {
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(useParams().date);
  const [users, setUsers] = useState([]);
  const [deliveryMen, setDeliveryMen] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [protocols, setProtocols] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filtersField, setFiltersField] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [filteredProtocols, setFilteredProtocols] = useState([]);
  const [coloredProtocols, setColoredProtocols] = useState([]);
  const [movementInFocus, setMovementInFocus] = useState(null);
  const [dateTaken, setDateTaken] = useState(false);
  const [resendingProtocols, setResendingProtocols] = useState(false);
  const [cancelments, setCancelments] = useState([]);
  const [totals, setTotals] = useState([]);
  const navigate = useNavigate();

  const filterableFields = [
    {value: 'entregador', label: 'Entregador', operators: ['%=%', '=', '!=']},
    {value: 'tabeliao', label: 'Tabelião', operators: ['%=%', '=', '!=']},
    {value: 'protocolo', label: 'Protocolo', operators: ['%=%', '=', '!=']},
    {value: 'nome', label: 'Nome', operators: ['%=%', '=', '!=']},
    {value: 'cpf_cnpj', label: 'CPF/CNPJ', operators: ['%=%', '=', '!=']},
    {value: 'cidade', label: 'Cidade', operators: ['%=%', '=', '!=']},
    {value: 'cep', label: 'CEP', operators: ['%=%', '=', '!=']},
    {value: 'endereco', label: 'Endereço', operators: ['%=%', '=', '!=']},
    {value: 'status', label: 'Status', operators: ['%=%', '=', '!=']},
    {value: 'type', label: 'Tipo', operators: ['%=%', '=', '!=']},
    {value: 'route', label: 'Rota', operators: ['%=%', '=', '!=']},
    {value: 'obs1', label: 'Observação 1', operators: ['%=%', '=', '!=']},
    {value: 'obs2', label: 'Observação 2', operators: ['%=%', '=', '!=']},
    {value: 'delivery_status1', label: 'Status de entrega 1', operators: ['%=%', '=', '!=']},
    {value: 'delivery_status2', label: 'Status de entrega 2', operators: ['%=%', '=', '!=']},
  ];

  const getProtocols = useCallback(async () => {
    setLoading(true);
    const data = await apiCall(`protocols?date=${encodeURIComponent(date)}`, 'GET', navigate);
    if (data.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Nenhum protocolo encontrado',
        text: 'Nenhum protocolo encontrado para a data selecionada',
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
    setDateTaken(data.dateTaken);
    setProtocols(data.protocols);
    setLoading(false);
  }, [date, navigate]);

  const getUsers = useCallback(async () => {
    setLoading(true);
    const data = await apiCall('users', 'GET', navigate);
    setUsers(data);
    setLoading(false);
  }, [navigate]);

  const getRoutes = useCallback(async () => {
    setLoading(true);
    const data = await apiCall('routes', 'GET', navigate);
    setRoutes(data);
    setLoading(false);
  }, [navigate]);

  const getCancelments = useCallback(async () => {
    setLoading(true);
    const data = await apiCall(`cancelments?date=${encodeURIComponent(date)}`, 'GET', navigate);
    setCancelments(data);
    setLoading(false);
  }, [date, navigate]);

  useEffect(() => {
    (async () => {
      await getProtocols();
      await getUsers();
      await getRoutes();
      await getCancelments();
    })();
  }, [getCancelments, getProtocols, getRoutes, getUsers]);

  useEffect(() => {
    if (users.length === 0) {
      return;
    }

    const userData = {};
    for (const user of users) {
      if (user.type === 'entregador') {
        userData[user.id] = {
          id: user.id,
          name: user.name,
          visit1: 0,
          visit2: 0,
          cancelment1: 0,
          cancelment2: 0,
          cancelment_not_done: 0,
          total: 0,
        };
      }
    }

    const usersSeen = new Set();

    const movements = [...protocols, ...cancelments];

    for (const protocol of movements) {
      const cancelmentRe = /\d{4}-\d{2}-\d{2}-\d{6}/;
      if (protocol.type === 'visita1') {
        userData[protocol.delivery_man_id].visit1++;
      } else {
        if (cancelmentRe.test(protocol.id)) {
          if (protocol.tabeliao == '1' || protocol.tabeliao == 'Tabeliao 1') {
            userData[protocol.entregador].cancelment1++;
          } else {
            userData[protocol.entregador].cancelment2++;
          }
          if (protocol.status === 'Não realizado') {
            userData[protocol.entregador].cancelment_not_done++;
          }
        } else {
          if (protocol.date_taken.slice(0, 10) == date) {
            userData[protocol.delivery_man_id].visit1++;
          } else {
            userData[protocol.delivery_man_id].visit2++;
          }
        }
      }
      userData[protocol.delivery_man_id || protocol.entregador].total++;
      usersSeen.add(protocol?.delivery_man_id || protocol?.entregador);
    }

    const data = [];
    for (const key in userData) {
      if (usersSeen.has(userData[key].id)) {
        data.push(userData[key]);
      }
    }

    setTotals(data);
  }, [protocols, users, cancelments, date]);

  useEffect(() => {
    const deliveryMen = [];
    users.forEach((user) => {
      console.log(user);
      if (user.type === 'entregador' && user.is_active) {
        deliveryMen.push(user);
      }
    });
    setDeliveryMen(deliveryMen);
  }, [users]);

  const sendToApp = useCallback(async () => {
    try {
      setLoading(true);
      await apiCall(
        `movements/send-to-app?date=${dayjs(date).format('YYYY-MM-DD')}`,
        'post',
        navigate
      );
      setLoading(false);
      getProtocols();
    } catch (ex) {
      console.error(ex);
      setLoading(false);
    }
  });

  const exportMovements = useCallback(async (t) => {
    setLoading(true);

    const formattedDate = dayjs(date).format('YYYY-MM-DD');

    const data = await apiCall(`movements/export?date=${formattedDate}&t=${t}`, 'post', navigate);
    const blob = await new Response(data).blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = url;
    a.download = `movimentos_${t}_${formattedDate}.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);

    setLoading(false);
  }, [protocols]);

  const generateT1Report = useCallback(async () => {
    setLoading(true);

    const formattedDate = dayjs(date).format('YYYY-MM-DD');

    try {
      const data = await apiCallRaw(`movements/generate-t1-report?date=${formattedDate}`, 'post', navigate);
      const uint8Array = new Uint8Array(data.split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([uint8Array], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `relatorio-t1-${formattedDate}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (ex) {
      console.error(ex);
      toast.error(ex.response.data.errors[0], {
        autoClose: 5000,
        position: 'bottom-left',
      });
    } finally {
      setLoading(false);
    }
  }, [date]);

  const generateT2Report = useCallback(async () => {
    setLoading(true);
    const formattedDate = dayjs(date).format('YYYY-MM-DD');

    try {
      const data = await apiCallRaw(`movements/generate-t2-report?date=${formattedDate}`, 'post', navigate);
      const uint8Array = new Uint8Array(data.split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([uint8Array], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `relatorio-t2-${formattedDate}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (ex) {
      console.error(ex);
      toast.error(ex.response.data.errors[0], {
        autoClose: 5000,
        position: 'bottom-left',
      });
    } finally {
      setLoading(false);
    }
  }, [date]);

  const customSrcs = useCallback({
    entregador: (row, filter, op) => {
      const user = users.find((u) => u.id == row.delivery_man_id);
      if (!user) {
        return false;
      }

      if (op === '%=%') {
        return user.name.toLowerCase().includes(filter.toLowerCase());
      } else if (op === '=') {
        return user.name == filter;
      } else if (op === '!=') {
        return user.name != filter;
      }
    },
    status: (row, filter, op) => {
      const str = (row.type === 'visita2' && row.delivery_status2 !== 'empty' && row.delivery_status2)
        || (row.type === 'visita1' && row.delivery_status1 !== 'empty' && row.delivery_status1) || '';

      if (op === '%=%') {
        return str.toLowerCase().includes(filter.toLowerCase());
      } else if (op === '=') {
        return str == filter;
      } else if (op === '!=') {
        return str != filter;
      }
    }
  }, [users]);

  const loadMovement = useCallback(async (row) => {
    setLoading(true);
    const movement = await apiCall(`protocols/${encodeURIComponent(row.protocolo)}`, 'GET', navigate);
    const protocolIdx = protocols.findIndex((p) => p.protocolo == row.protocolo);
    const newProtocols = [];
    protocols.forEach((p, i) => {
      if (i === protocolIdx) {
        newProtocols.push(movement[0]);
      } else {
        newProtocols.push(p);
      }
    });
    setProtocols(newProtocols);
    setMovementInFocus(movement[0]);
    setLoading(false);
  }, [protocols]);

  useEffect(() => {
    const colored = [];
    for (const protocol of filteredProtocols) {
      let str;
      console.log(protocol);
      if (dateTaken.done && +dateTaken.really_done === 0 && !protocol.go_to_second_visit) {
        str = 'Concluído';
      } else {
        str = protocol.situation;
      }

      if (str === 'Concluído') {
        colored.push({
          ...protocol,
          color: 'green',
        });
      } else if (str === 'Em rota') {
        colored.push({
          ...protocol,
          color: 'orange',
        });
      } else {
        colored.push({
          ...protocol,
          color: 'blue',
        });
      }
    }
    setColoredProtocols(colored);
  }, [filteredProtocols, dateTaken]);

  return (
    <Page title={'Movimento do dia ' + dayjs(date).format('DD/MM/YYYY')}>
      {loading > 0 && (
        <>
          <Backdrop open={true} sx={{ zIndex: 9 }} />
          <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-20px', marginLeft: '-20px', zIndex: 99 }} />
        </>
      )}
      {movementInFocus && (
        <ViewMovement users={users} movement={movementInFocus} doneCb={(v) => {
          setProtocols([...protocols]);
          setFilteredProtocols([...filteredProtocols]);
          setMovementInFocus(null);
        }} />
      )}
      <Paper sx={{ padding: '1rem' }}>
        <Typography sx={{
          fontWeight: '900',
          fontSize: '1.5rem',
        }}>{'Movimento do dia ' + dayjs(date).format('DD/MM/YYYY')}</Typography>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <MultiFilter data={protocols} customSrcs={customSrcs} fields={filterableFields} setRows={setFilteredProtocols} />

        <Button variant='outlined' sx={{}} onClick={() => {
          generateT1Report();
        }}>Gerar Relatório T1</Button>

        <Button variant='outlined' sx={{ ml: '1%' }} onClick={() => {
          generateT2Report();
        }}>Gerar Relatório T2</Button>

        <Button variant='text' sx={{ ml: '1%' }} onClick={() => {
          window.open(`/app/cancelamento/${dayjs(date).format('YYYY-MM-DD')}`, '_blank');
        }}>Ver Cancelamentos</Button>

        <Button variant='text' sx={{ ml: '1%' }} onClick={() => {
          setResendingProtocols(true);
        }}>Reenviar Protocolos</Button>

        {resendingProtocols && (
          <ResendProtocols date={date} users={deliveryMen} doneCb={(v) => setResendingProtocols(false)} />
        )}

        {filters.map((filter, index) => (
          <div key={index}>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Button
              sx={{
                width: '11%',
                height: '56px',
                verticalAlign: 'top',
              }}
              variant="contained" startIcon={<Close/>} onClick={() => {
              const newFilters = filters.filter((f, i) => i !== index);
              const newFiltersField = filtersField.filter((f, i) => i !== index);
              setFilters(newFilters);
              setFiltersField(newFiltersField);
              setFilterCount(filterCount - 1);
            }}>Remover</Button> 

            <TextField sx={{
              ml: '1%',
              width: '50%',
             }} label='Expressão' variant='outlined' value={filter} onChange={(e) => {
              const newFilters = filters.map((f, i) => i === index ? e.target.value : f);
              setFilters(newFilters);
            }} />

            <FormControl
              sx={{
                mt: 0,
                mb: 0, 
                ml: '1%',
                width: '37%',
                verticalAlign: 'top',
                display: 'inline-block',
              }}
            >
              <InputLabel>Campo</InputLabel>
              <Select
                fullWidth
                label='Campo'
                placeholder='Campo'
                value={filtersField[index]}
                onChange={(e) => {
                  filtersField[index] = e.target.value;
                  setFiltersField([...filtersField]);
                }}
              >
                {filterableFields.map((field, index) => (
                  <MenuItem key={field.value} value={field.value}>{field.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

          </div>
        ))}

        <Divider sx={{ mt: 2, mb: 2 }} />

        <BetterTable columns={[
            {field: 'name', headerName: 'Nome', width: 0.2 * 1132},
            {field: 'visit1', headerName: '1ª Visita', width: 0.1175 * 1132},
            {field: 'visit2', headerName: '2ª Visita', width: 0.1175 * 1132},
            {field: 'cancelment1', headerName: 'Cancelamento 1', width: 0.1175 * 1132},
            {field: 'cancelment2', headerName: 'Cancelamento 2', width: 0.1175 * 1132},
            {field: 'cancelment_not_done', headerName: 'Cancelamento não realizado', width: 0.15 * 1132},
            {field: 'total', headerName: 'Total', width: 0.2 * 1132},
          ]}
          rows={totals}
          rowHeight={40}
        />

        <Divider sx={{ mt: 2, mb: 2 }} />

        <BetterTable columns={[
          {
            headerName: 'Disponível',
            field: 'available_to_app',
            width: 0.1 * 1166,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
              const { row } = params;
              return (
                <div>{
                  dateTaken.done  && +dateTaken.really_done === 0 ? 'Concluído' : row.situation
                }</div>
              );
            },
          },
          {
            field: 'protocolo',
            headerName: 'Protocolo',
            width: 0.24 * 1166,
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 0.11 * 1166,
            sortable: false,
            renderCell: (params) => {
              const { row } = params;
              return (
                <div>{ (row.type === 'visita2' && row.delivery_status2 !== 'empty' && row.delivery_status2)
                  || (row.type === 'visita1' && row.delivery_status1 !== 'empty' && row.delivery_status1) }</div>
              );
            }
          },
          {
            field: 'type',
            headerName: 'Tipo',
            width: 0.1 * 1166,
          },
          {
            headerName: 'Observação',
            field: 'obs1',
            width: 0.40 * 1166,
            renderCell: (params) => {
              const { row } = params;
              return (
                <div>{ row.type === 'visita1' ? row.obs1 : row.obs2 }</div>
              );
            }
          },
          {
            field: 'more',
            headerName: 'Mais',
            align: 'right',
            width: 0.05 * 1166, 
            filterable: false,
            sortable: false,
            renderCell: (params) => {
              const row = {params};
              return (
                <IconButton onClick={() => loadMovement(row.params.row)}>
                  <More />
                </IconButton>
              );
            },
          },
        ]} rows={coloredProtocols} />

        <Box sx={{
          mt: 2,
          mb: 2,
          mr: '2%',
          padding: '2rem',
          display: 'inline-flex',
          width: '23.5%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          backgroundColor: '#f5f5f5',
          border: '8px solid #e0e0e0',
          '&:hover': {
            backgroundColor: '#fff',
            borderColor: '#0288d1',
            color: '#0288d1',
          }
        }} onClick={() => exportMovements('t1')}>
          <FileDownload sx={{ fontSize: '5rem' }} />
          <Typography sx={{
            fontWeight: '900',
            fontSize: '2rem',
          }}>Exportar T1</Typography>
        </Box>
         <Box sx={{
          mt: 2,
          mb: 2,
          mr: '2%',
          padding: '2rem',
          display: 'inline-flex',
          width: '23.5%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          backgroundColor: '#f5f5f5',
          border: '8px solid #e0e0e0',
          '&:hover': {
            backgroundColor: '#fff',
            borderColor: '#0288d1',
            color: '#0288d1',
          }
        }} onClick={() => exportMovements('t2')}>
          <FileDownload sx={{ fontSize: '5rem' }} />
          <Typography sx={{
            fontWeight: '900',
            fontSize: '2rem',
          }}>Exportar T2</Typography>
        </Box>
        {protocols.length > 0 && !dateTaken.all_released && (
          <Box sx={{
            mt: 2,
            mb: 2,
            padding: '2rem',
            display: 'inline-flex',
            width: '49%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: '#f5f5f5',
            border: '8px solid #e0e0e0',
            '&:hover': {
              backgroundColor: '#fff',
              borderColor: '#0288d1',
              color: '#0288d1',
            }
          }} onClick={() => sendToApp()}>
            <SendToMobile sx={{ fontSize: '5rem' }} />
            <Typography sx={{
              fontWeight: '900',
              fontSize: '2rem',
            }}>Enviar Para o App</Typography>
          </Box>)}

      </Paper>
    </Page>
  );
};
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { LoadingIndicator } from './LoadingIndicator';
import { apiCall } from '../services/apiCall';

export const ResendProtocols = ({ doneCb, users, date }) => {
  const [deliveryMen, setDeliveryMen] = useState(users.filter(user => user.type === 'entregador'));
  const [deliveryMan, setDeliveryMan] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const resendProtocols = useCallback(async (userId) => {
    setLoading(true);
    await apiCall('protocols/resend', 'post', navigate, {id: deliveryMan, date});
    setLoading(false);
    doneCb(true);
  }, [deliveryMan, date]);

  return (
    <>
    {loading && (
      <LoadingIndicator />
    )}
    <Dialog open={true} onClose={() => doneCb(false)}>
      <DialogTitle>Reenviar Protocolos</DialogTitle>
      <DialogContent sx={{minWidth: 500}}>
        <FormControl fullWidth
          sx={{
          }}
        >
          <InputLabel>Entregador</InputLabel>
          <Select 
            fullWidth
            label='Entregador'
            placeholder='Entregador'
            value={deliveryMan}
            onChange={(e) => setDeliveryMan(e.target.value)}
          >
            {deliveryMen.map((deliveryMan, index) => (
              <MenuItem key={index} value={deliveryMan.id}>{deliveryMan.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => doneCb(false)}>Cancelar</Button>
        <Button onClick={() => resendProtocols()} variant='contained'>Reenviar</Button>
      </DialogActions>

    </Dialog>
    </>
  );
};
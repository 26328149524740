import { Backdrop } from "@mui/material";

export const ImagePopUp = ({ image, onClose }) => {
  return (
    <>
      <Backdrop onClick={onClose} open={true} style={{ zIndex: 9999999 }} />
      <img
        onClick={onClose}
        style={{
          zIndex: 999999999999999,
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '80%',
          maxHeight: '80%'
        }}
        src={'data:image/png;base64,' + image} />
    </>
  )
};
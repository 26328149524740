import { Button, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Page } from "./Page";
import { MultiFilterOnline } from "./MultiFilterOnline";
import { Add, DocumentScanner } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { apiCall } from "../services/apiCall";
import { useNavigate } from "react-router-dom";
import { LoadingIndicator } from "./LoadingIndicator";
import { AddFailure } from "./AddFailure";
import { EditFailure } from "./EditFailure";
import dayjs from "dayjs";
import 'dayjs/locale/pt-br';

export const Failures = () => {
  const [deliveryMen, setDeliveryMen] = useState([]);
  const [addingFailure, setAddingFailure] = useState(false);
  const [failures, setFailures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [failureBeingUpdated, setFailureBeingUpdated] = useState(null);
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

   const filterableFields = [
    {value: 'limit', label: 'Limite de resultados', operators: ['=']},
    {value: 'delivery_man', label: 'Entregador', operators: ['%=%', '=', '!=']},
    {value: 'date', label: 'Data', operators: ['>=', '<=', '=']},
    {value: 'type', label: 'Tipo', operators: ['%=%', '=', '!=']},
    {value: 'text', label: 'Texto', operators: ['%=%', '=', '!=']},
  ];


  useEffect(() => {
    (async () => {
      setLoading(true);
      const users = await apiCall('users', 'get', navigate);
      setDeliveryMen(users.filter(u => u.type === 'entregador'));
      setLoading(false);
    })();
  }, []);

  const generateReport = useCallback(async () => {
    setLoading(true);
    const body = {
      failures: failures.map(f => {
        return {
          ...f,
          delivery_man: deliveryMen.find(d => d.id == f.delivery_man_id).name,
        };
      }),
    };

    const data = await apiCall('failures/report', 'post', navigate, body);
    const uint8Array = new Uint8Array(data.split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `relatorio-de-falhas.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    setLoading(false);
  }, [failures]);

  useEffect(() => {
    if (failures.length === 0) {
      setMsg('Nenhuma falha encontrada');
    } else if (failures.length === 1) {
      setMsg('1 falha encontrada');
    } else {
      setMsg(`${failures.length} falhas encontradas`);
    }
  });

  return (
    <>
      {loading && (
        <LoadingIndicator />
      )}

      <Page title="Gestor de Falhas">
        <Paper sx={{
          padding: '1rem',
        }}>
          <Typography sx={{
            fontWeight: '900',
            fontSize: '1.5rem',
          }}>Gestor De Falhas</Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <MultiFilterOnline endpoint={'failures'} fields={filterableFields} setRows={r => setFailures(r.failures)} />
          <Button variant="contained" sx={{ mt: 2 }}
            startIcon={<Add />}
            onClick={() => setAddingFailure(true)}>Adicionar falha</Button>
          <Button startIcon={<DocumentScanner />} variant="outlined" sx={{ mt: 2, ml: 2 }} onClick={generateReport}>Exportar Relatório Com Dados Visíveis</Button>

          <Typography sx={{ mt: 4, fontWeight: 'bold' }}>{msg}</Typography>

          <TableContainer sx={{ mt: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Entregador</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell sx={{width: '50%'}}>Texto</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
             </TableHead>
             <TableBody>
                {failures.map((failure, index) => (
                  <TableRow key={index}>
                    <TableCell>{dayjs(failure.date).format('DD/MM/YYYY') }</TableCell>
                    <TableCell>{deliveryMen.find(d => d.id == failure.delivery_man_id).name}</TableCell>
                    <TableCell>{failure.type}</TableCell>
                    <TableCell>{failure.text}</TableCell>
                    <TableCell align="right"><Button variant="outlined" onClick={() => setFailureBeingUpdated(failure)}>Editar</Button></TableCell>
                  </TableRow>
                ))}
             </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Page>

      {addingFailure && (
        <AddFailure doneCb={added => {
          if (!added) {
            setAddingFailure(false);
          } else {
            window.location.reload();
          }
        }} deliveryMen={deliveryMen} />
      )}
      {failureBeingUpdated && (
        <EditFailure doneCb={updated => {
          if (!updated) {
            setFailureBeingUpdated(null);
          } else {
            window.location.reload();
          }
        }} deliveryMen={deliveryMen} failure={failureBeingUpdated} />
      )}
    </>
  )
};
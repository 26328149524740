import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button
} from '@mui/material';
import { AdminPanelSettings, LocalShipping } from '@mui/icons-material';

export const ViewUser = ({ doneCb, user }) => {
  return (
    <Dialog open={true} onClose={() => doneCb(false)}>
      <DialogTitle>Visualizar usuário</DialogTitle>
      <DialogContent sx={{
        minWidth: 500,
      }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell><b>{user.id}</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell><b>{user.name}</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Telefone</TableCell>
                <TableCell><b>{user.phone}</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tipo</TableCell>
                <TableCell>
                  {user.roles.indexOf('admin') !== -1 ? (
                    <div><AdminPanelSettings sx={{verticalAlign: 'middle'}} /> <b>Administrador</b></div>
                  ) : (
                    <div><LocalShipping sx={{verticalAlign: 'middle'}} /> <b>Entregador</b></div>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>{user.is_active ? 'Ativo' : 'Inativo'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Assinatura</TableCell>
                <TableCell>{user.signature_line1}<br />{user.signature_line2}</TableCell>
              </TableRow>
              {user.extra && (
                <TableRow>
                  <TableCell colSpan={2}>
                    {user.extra.split('\n').map((str, index) => 
                      <div key={index}>{str}</div>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => doneCb(false)}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};
import { useState } from 'react';
import {
  Box, Button, Typography,
} from '@mui/material';

export const DragAndDrop = ({files, setFiles}) => {
  const [instructions, setInstructions] = useState('Arraste e solte o(s) arquivo(s) aqui');
  const [showButton, setShowButton] = useState(true);

  const processFiles = async (files) => {
    let arr = [];
    for (const file of files) {
      arr.push(file);
    }
    setFiles(arr);
  };

  const selectFiles = () => {
    const fileEl = document.createElement('input');
    fileEl.type = 'file';
    fileEl.multiple = true;

    document.body.appendChild(fileEl);
    fileEl.click();
    fileEl.remove();

    fileEl.addEventListener('change', async (e) => {
      const files = e.target.files;
      processFiles(files);
    });
  };

  return (
    <>
      {(!files || files.length === 0) ? (
        <Box
          sx={{
            width: '100%',
            height: '300px',
            border: '2px dashed black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          onDragEnter={(e) => {
            setInstructions('Solte o(s) arquivo(s) aqui');
            setShowButton(false);
            e.preventDefault();
          }}
          onDragOver={(e) => {
            setInstructions('Solte o(s) arquivo(s) aqui');
            setShowButton(false);
            e.preventDefault();
          }}
          onDragLeave={(e) => {
            setInstructions('Arraste e solte o(s) arquivo(s) aqui');
            setShowButton(true);
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            processFiles(files);
          }}
        >
          <Typography sx={{
            fontWeight: '900',
            fontSize: '2rem',
          }}>{ instructions }</Typography>
          {showButton && (
            <>
              <Box sx={{
                mt: 4,
              }}>
                ou
              </Box>

              <Box sx={{
                mt: 4,
              }}>
                <Button variant='outlined' onClick={() => selectFiles()}>selecione o(s) arquivo(s)</Button>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '300px',
            border: '2px dashed black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{
            fontWeight: '900',
            fontSize: '1rem',
          }}>{ files.map(f => f.name).join(', ') }</Typography>
        </Box>
      )}
    </>
  );
}
import { Page } from './Page';
import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Input,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tab,
} from '@mui/material';
import {
  Done, ExpandMore, FileDownload, Info, More, SendToMobile, UploadFileOutlined,
  Search, Add, ReadMore,
} from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { apiCall } from '../services/apiCall';
import { Colors } from '../Colors';

export const SinglePage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [chosenFilter, setChosenFilter] = useState('Data');
  const [movements, setMovements] = useState([]);
  const navigate = useNavigate();
  console.log(movements);

  const filterTypes = [
    'Data',
    'Protocolo',
  ];

  const findProtocol = useCallback(async () => {
    try {
      const data = await apiCall(`protocols/${encodeURIComponent(searchTerm)}`, 'get', navigate);
      console.log(data);
      setMovements(data);
    } catch (ex) {
      console.error(ex);
    }
  }, [searchTerm]);

  const findByDate = useCallback(async () => {
    if (!/^\d{2}\/\d{2}\/\d{4}$/.test(searchTerm)) {
      alert('Data inválida');
      return;
    }

    try {
      const data = await apiCall(`dates-taken?date=${encodeURIComponent(searchTerm)}`, 'get', navigate);
      console.log(data);
      setMovements(data);
    } catch (ex) {
      console.error(ex);
    }
  }, [searchTerm]);

  useEffect(() => {
    (async () => {
      const data = await apiCall('movements?page=0', 'get', navigate);
      setMovements(data);
    })();
  }, []);

  return (
    <Paper sx={{
      padding: '1rem',
    }}>
      <Typography sx={{
        fontWeight: '900',
        fontSize: '1.5rem',
      }}>Movimentos</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <FormControl
        sx={{
          mt: 1,
          mb: 2,
          width: '49%',
          mr: 0,
        }}
      >
        <TextField fullWidth variant='outlined' label='Buscar'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </FormControl>

      <FormControl
        sx={{
          mt: 1,
          mb: 2,
          width: '25%',
          ml: '1%',
        }}
      >
        <InputLabel>Tipo De Filtro</InputLabel>
        <Select
          fullWidth
          label='Tipo De Filtro'
          placeholder='Tipo De Filtro'
          value={chosenFilter}
          onChange={(e) => setChosenFilter(e.target.value)}
        >
          {filterTypes.map((filterType) => (
            <MenuItem key={filterType} value={filterType}>{filterType}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        sx={{
          mt: 1,
          mb: 2,
          width: '13%',
          ml: '1%',
          mr: '1%'
        }}
      >
        <Button variant='contained' color='primary' onClick={() => {
          if (chosenFilter === 'Data') {
            findByDate();
          } else if (chosenFilter === 'Protocolo') {
            findProtocol();
          }
        }}
          fullWidth
          endIcon={<Search />}
          sx={{
            height: '56px',
          }}
        >
          Filtrar
        </Button>
      </FormControl>

      <FormControl
        sx={{
          mt: 1,
          mb: 2,
          width: '8%',
          ml: '1%',
          paddingLeft: '2%',
          borderLeft: 'rgba(0, 0, 0, 0.12) 1px solid',
        }}
      >
        <Button sx={{
          height: '56px'
        }}
          variant='contained' color='primary'
          onClick={() => window.open('novo-movimento', '_blank')} 
        >
            <Add />
        </Button>
      </FormControl>

      <TableContainer sx={{
        mt: 2,
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Data</TableCell>
                {movements.length > 0 && movements[0].protocolo && (
                  <TableCell>
                    Protocolo
                  </TableCell>
                )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {movements.map((movement, index) => (
              <TableRow key={movement.date + '-' + index} sx={{
                backgroundColor: movement.done && +movement.really_done === 0 ? Colors.green.bg
                  : movement.on_the_go ? Colors.orange.bg : Colors.blue.bg,
                color: movement.done && +movement.really_done === 0 ? Colors.green.fg
                  : movement.on_the_go ? Colors.orange.fg : Colors.blue.fg,
              }}>
                <TableCell
                  sx={{
                    color: movement.done && +movement.really_done === 0 ? Colors.green.fg
                      : movement.on_the_go ? Colors.orange.fg : Colors.blue.fg,
                  }}
                >{movement.done && +movement.really_done === 0 ? 'Concluído' : movement.on_the_go ? 'Em rota' : 'Em Aberto'}</TableCell>
                <TableCell
                  sx={{
                    color: movement.done && +movement.really_done === 0 ? Colors.green.fg
                      : movement.on_the_go ? Colors.orange.fg : Colors.blue.fg,
                  }}
                >{ dayjs(movement.date).format('DD/MM/YYYY') } </TableCell>
                {movements.length > 0 && movements[0].protocolo && (
                  <TableCell
                    sx={{
                      color: movement.done && +movement.really_done === 0 ? Colors.green.fg
                        : movement.on_the_go ? Colors.orange.fg : Colors.blue.fg,
                    }}
                  >
                    <a href={'movimento/' + movement.date.slice(0, 10) + '?protocolo*%3D=' + encodeURIComponent(movement.protocolo)} target='_blank'>{movement.protocolo}</a>
                  </TableCell>
                )}
                <TableCell align='right'
                    sx={{
                      color: movement.done && +movement.really_done === 0 ? Colors.green.fg
                        : movement.on_the_go ? Colors.orange.fg : Colors.blue.fg,
                    }}
                >
                  <IconButton onClick={() => window.open(`movimento/${dayjs(movement.date).format('YYYY-MM-DD')}`)}>
                    <ReadMore />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export const Movements = () => {
  const [loading, setLoading] = useState(0);
  const [routes, setRoutes] = useState([]);
  const [cepRanges, setCepRanges] = useState([]);
  const [users, setUsers] = useState([]);
  const [hasRoutes, setHasRoutes] = useState(false);
  const [hasCepRanges, setHasCepRanges] = useState(false);
  const [hasUsers, setHasUsers] = useState(false);
  const navigate = useNavigate();

  let tempX = loading;

  const childSetLoading = (v => {
    let x = +v;
    if (x === 0) {
      x = -1;
    }
    tempX = tempX + x;
    setLoading(tempX);
  });

  const loadRoutes = useCallback(async () => {
    try {
      const data = await apiCall('routes', 'get', navigate);
      setRoutes(data.sort((a, b) => +a.number - +b.number));
      setHasRoutes(true);
    } catch (ex) {
      console.error(ex);
    }
  }, [navigate]);

  const loadCepRanges = useCallback(async () => {
    try {
      const data = await apiCall('cep-ranges', 'get', navigate);
      setCepRanges(data);
      setHasCepRanges(true);
    } catch (ex) {
      console.error(ex);
    }
  }, [navigate]);

  const loadUsers = useCallback(async () => {
    try {
      const data = await apiCall('users', 'get', navigate);
      setUsers(data);
      setHasUsers(true);
    } catch (ex) {
      console.error(ex);
    }
  }, [navigate]);

  useEffect(() => {
    loadRoutes();
    loadCepRanges();
    loadUsers();
  }, []);

  return (
    <Page title='Movimentos'>
      {loading > 0 && (
        <>
          <Backdrop open={true} sx={{ zIndex: 999 }} />
          <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-20px', marginLeft: '-20px', zIndex: 9999 }} />
        </>
      )}

      <SinglePage />
    </Page>
  );
};
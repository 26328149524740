import axios from 'axios';
import Swal from 'sweetalert2';

export const apiCallRaw = async (endpoint, method, navigate, body = null, headers = {}) => {
  try {
    const response = await axios({
      method,
      url: `/app/api/${endpoint}`,
      data: body,
      headers: {
        ...headers,
        'Authorization': localStorage.getItem('token')
      },
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.status === 401) {
      navigate('/app/login');
      return;
    }
    throw err;
  }
};

export const apiCall = async (endpoint, method, navigate, body = null, headers = {}) => {
  try {
    return await apiCallRaw(endpoint, method, navigate, body, headers);;
  } catch (err) {
    console.log(err);
    if (err.response?.data?.errors) {
      // call popup with errors
      const res = await Swal.fire({
        title: '<strong>Erro</strong>',
        icon: 'error',
        html: err.response.data.errors.map((error) => {
          return `<p>- ${error}</p>`;
        }).join(''),
        
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: 'Recarregar',
      });

      if (res.isConfirmed) {
        window.location.reload(true);
      }
    }
  }
}
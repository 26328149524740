import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import * as yup from 'yup';
import { apiCall } from '../services/apiCall';
import { ErrorBox } from './ErrorBox';

export const EditNotary = ({ doneCb, notary }) => {
  const [number, setNumber] = useState(notary.number);
  const [name, setName] = useState(notary.name);
  const [email, setEmail] = useState(notary.email || '');
  const [cnpj, setCnpj] = useState(notary.cnpj || '');
  const [address, setAddress] = useState(notary.address || '');
  const [phone, setPhone] = useState(notary.phone || '');
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const handleEditNotary = async () => {
    const schema = yup.object().shape({
      email: yup
              .string()
              .email('Email deve ser válido')
              .optional(),

      number: yup
                .string()
                .test(
                  'is-number',
                  'Número deve ser um número maior do que 0',
                  (value) => !isNaN(value) && value > 0),
      name: yup
              .string('Nome deve ser uma string')
              .min(2, 'O nome deve ter pelo menos 2 caracteres')
              .required('Nome é obrigatório'),
    });

    try {
      await schema.validate({
        number,
        name,
      }, {abortEarly: false});

      try {
        await apiCall(`notaries/${notary.id}`, 'put', navigate, {
          number,
          name,
          cnpj,
          address,
          phone,
          email,
        });
        doneCb(true);
      } catch (err) {
        console.log(err);
        if (err.response.status === 422) {
          if (err.response.data.number) {
            setErrors(['Número já cadastrado']);
          }
        }
        else {
          doneCb(false);
        }
      }
    } catch (err) {
      setErrors(err.errors);
    }
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Editar cartório</DialogTitle>
      <DialogContent sx={{minWidth: 500}}>
        <TextField
          autoFocus
          label='Número'
          placeholder='Número'
          type='number'
          sx={{
            mb: 1,
            mt: 1,
            width: '30%',
            marginRight: '5%',
          }}
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
        <TextField 
          label='CNPJ (opcional)'
          placeholder='CNPJ (opcional)'
          type='text'
          sx={{
            mb: 1,
            mt: 1,
            width: '65%',
          }}
          value={cnpj}
          onChange={(e) => setCnpj(e.target.value)}
        />
        <TextField
          fullWidth
          label='Nome'
          placeholder='Nome'
          type='text'
          sx={{
            mb: 1,
            mt: 1,
          }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          fullWidth
          label='E-mail (opcional)'
          placeholder='E-mail (opcional)'
          type='email'
          sx={{
            mb: 1,
            mt: 1,
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label='Endereço (opcional)'
          placeholder='Endereço (opcional)'
          type='text'
          sx={{
            mb: 1,
            mt: 1,
          }}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <TextField
          fullWidth
          label='Telefone (opcional)'
          placeholder='Telefone (opcional)'
          type='text'
          sx={{
            mb: 1,
            mt: 1,
          }}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        {errors.length > 0 && (
          <div sx={{mt: 2}}>
            <ErrorBox errors={errors} />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => doneCb(false)}>Cancelar</Button>
        <Button onClick={handleEditNotary} variant='contained'>Salvar</Button>
      </DialogActions>

    </Dialog>
  );
};
import { Page } from './Page';
import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Input,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tab,
} from '@mui/material';
import {
  Done, ExpandMore, FileDownload, Info, More, SendToMobile, UploadFileOutlined,
  Search, Add, ReadMore, EventRepeat,
} from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { DragAndDrop } from './DragAndDrop';
import { ErrorBox } from './ErrorBox';
import { BetterTable } from './BetterTable';
import { apiCall } from '../services/apiCall';


export const NewMovement = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(dayjs());
  const [dateMode, setDateMode] = useState('unique');
  const [ctxFiles, setCtxFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState([]);
  const [movements, setMovements] = useState([]);
  const [cancelments, setCancelments] = useState([]);
  const [columns, setColumns] = useState([]);
  const [users, setUsers] = useState([]);
  const [cepRanges, setCepRanges] = useState([]);
  const [rows, setRows] = useState([]);
  const [hasMovements, setHasMovements] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [canProceedToPhase2, setCanProceedToPhase2] = useState(false);
  const [phase, setPhase] = useState(0);
  const [totals, setTotals] = useState([]);
  const [phase1Columns, setPhase1Columns] = useState([]);
  const [usersColumns, setUsersColumns] = useState([]);
  const [usersRows, setUsersRows] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [hasRoutes, setHasRoutes] = useState(false);
  const navigate = useNavigate();

  const addRouteToMovement = useCallback((movement) => {
    const cep = +movement.cep.replace('-', '');
    const cepRange = cepRanges.find((cepRange) => {
      const rangeStart = +cepRange.range_start.replace('-', '');
      const rangeEnd = +cepRange.range_end.replace('-', '');
      return cep >= rangeStart && cep <= rangeEnd;
    });
    if (cepRange) {
      const route = routes.find((route) => route.id == cepRange.route);
      if (route) {
        movement.rota = route.number;
      }
    }
  }, [cepRanges, routes]);

  const loadMovements = useCallback(async () => {
    setLoading(true);
    try {
      const data = await apiCall('protocols/second-visit', 'post', navigate);
      for (const row of data) {
        row.type = 'visita2';
        row.rota = row.route;
      }
      setMovements(data);
      setRows(data);
      setHasMovements(true);
    } catch (ex) {
      console.error(ex);
    }
    setLoading(false);
  }, [navigate, addRouteToMovement]);

  const loadRoutes = useCallback(async () => {
    setLoading(true);
    try {
      const data = await apiCall('routes', 'get', navigate);
      setRoutes(data.sort((a, b) => +a.number - +b.number));
      setHasRoutes(true);
    } catch (ex) {
      console.error(ex);
    }
    setLoading(false);
  }, [navigate]);

  const loadUsers = useCallback(async () => {
    setLoading(true);
    try {
      const data = await apiCall('users', 'get', navigate);
      setUsers(data);
    } catch (ex) {
      console.error(ex);
    }
    setLoading(false);
  }, [navigate]);

  const loadCepRanges = useCallback(async () => {
    setLoading(true);
    try {
      const data = await apiCall('cep-ranges', 'get', navigate);
      setCepRanges(data);
    } catch (ex) {
      console.error(ex);
    }
    setLoading(false);
  }, [navigate]);

  const convert = async () => {
    setLoading(true);
    setHasMovements(false);

    let arr = [];
    let ctxId = ctxFiles.length;

    for (const file of files) {
      const blob = await new Response(file).blob();
      const formData = new FormData();
      formData.append('pdf', blob);

      try {
        const data = await apiCall('movements/convert', 'post', navigate, formData);
        for (const row of data) {
          row.ctxIdx = ctxId;
          row.type = 'visita1';
          row.id = 'temp-' + row.protocolo;
          row.tabeliao = row.tabeliao.trim().slice(0, 1);
          row.date = date.format('YYYY-MM-DD');
          addRouteToMovement(row);
        }
        arr.push(...data);
      } catch (ex) {
        console.error(ex);
        setErrors(ex.response.data.errors);
        break;
      }

      ctxId++;
    }

    setMovements([...movements, ...arr]);
    setRows([...rows, ...arr]);
    setCtxFiles([...ctxFiles, ...files]);
    setFiles([]);
    setHasMovements(true);
    setLoading(false);
  };
 
  const processFiles = async () => {
    if (files.length === 0) {
      setErrors(['Nenhum arquivo selecionado']);
      return;
    }
    if (date === null) {
      setErrors(['Data inválida']);
      return;
    }

    setErrors([]);
    convert();
  };

  useEffect(() => {
    (async () => {
      await loadCepRanges();
      await loadRoutes();
      await loadUsers();
      await loadMovements();
    })();
  }, []);

  useEffect(() => {
    setCanProceed(movements.every((movement) => movement.rota && movement.rota !== ''));
  }, [movements]);

  useEffect(() => {
    setColumns([
      {field: 'tabeliao', headerName: 'Tabelião', width: 0.05 * 1132,
        renderCell: (params) => {
          const { row } = params;
          return (
            <>
              {row.tabeliao} {row.type === 'visita2' && <EventRepeat />}
            </>
          );
        },
      },
      {field: 'protocolo', headerName: 'Protocolo', width: 0.15 * 1132},
      {field: 'nome', headerName: 'Nome', width: 0.175 * 1132},
      {field: 'cidade', headerName: 'Cidade', width: 0.15 * 1132},
      {field: 'cep', headerName: 'CEP', width: 0.10 * 1132},
      {field: 'endereco', headerName: 'Endereço', width: 0.30 * 1132},
      {
        field: 'rota',
        headerName: 'Rota',
        renderCell: (params) => {
          const { row } = params;
          return (
            <FormControl fullWidth
              sx={{
                mt: 1,
                mb: 1,
              }}
            >
              <InputLabel>Rota</InputLabel>
              <Select 
                fullWidth
                label='Rota'
                placeholder='Rota'
                value={row.rota || ''}
                onChange={(e) => {row.rota = e.target.value; setRows([...rows]); setMovements([...movements]);}}
              >
                <MenuItem value=''>Sem Rota</MenuItem>
                {routes.map((route) => (
                  <MenuItem key={+route.id} value={`${route.number}`}>{route.number}</MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
        width: 0.075 * 1132,
        sortComparator: (v1, v2) => {
          if (!v1 && !v2) {
            return 0;
          }
          if (!v1 && v2) {
            return -1;
          }
          if (v1 && !v2) {
            return 1;
          }

          const r1 = routes.find(r => r.number == v1.split(' - ')[0]);
          const r2 = routes.find(r => r.number == v2.split(' - ')[0]);
          if (r1 && r2) {
            return +r1.number - +r2.number;
          }
          if (r1 && !r2) {
            return -1;
          }
          if (!r1 && r2) {
            return 1;
          }
          return 0;
        },
      },
    ]);
  }, [movements, routes, rows]);

  useEffect(() => {
    setCanProceedToPhase2(totals.every((t) => t.delivery_man_id && t.delivery_man_id >= 0));
  }, [totals]);

  useEffect(() => {
    for (const user of users) {
      user.total = 0;
    }

    for (const total of totals) {
      const user = users.find(u => u.id == total.delivery_man_id);
      if (user) {
        user.total += +total.total;
      }
    }

    const rows = [];
    for (const user of users) {
      if (user.roles.includes('entregador') === false) {
        continue;
      }
      const row  ={
        id: user.id,
        name: user.name,
        visit1: 0,
        visit2: 0,
        cancelment: 0,
        cancelment2: 0,
        total: 0,
      };

      const allTotals = totals.filter((t) => t.delivery_man_id == user.id);
      for (const t of allTotals) {
        row.visit1 += +t.visit1;
        row.visit2 += +t.visit2;
        row.cancelment += +t.cancelment;
        row.cancelment2 += +t.cancelment2;
        row.total += +t.total;
      }

      rows.push(row);
    }

    setUsersRows(rows.filter((row) => row.total > 0));
  }, [totals, users]);

  const moveToPhase1 = useCallback(async () => {
    const routesTotals = {};
    const newTotals = [...totals];

    for (const route of routes) {
      routesTotals[route.number] = {
        visit1: 0,
        visit2: 0,
        cancelment: 0,
        cancelment2: 0,
        total: 0,
      };
    }

    for (const total of totals) {
      if (total) {
        routesTotals[total.route].cancelment  = +total.cancelment;
        routesTotals[total.route].cancelment2  = +total.cancelment2;
        routesTotals[total.route].total = +total.cancelment + +total.cancelment2;
      }
    }

    for (const movement of movements) {
      if (movement.rota) {
        if (movement.type === 'visita1') {
          routesTotals[movement.rota].visit1++;
          routesTotals[movement.rota].total++;
        } else {
          routesTotals[movement.rota].visit2++;
          routesTotals[movement.rota].total++;
        }
      }
    }

    for (const i in newTotals) {
      newTotals[i] = {
        ...newTotals[i],
        ...routesTotals[newTotals[i].route],
      }
    }

    if (newTotals.length === 0) {
      for (const route of routes) {
        newTotals.push({
          id: route.id,
          route: route.number,
          ...routesTotals[route.number],
          delivery_man_id: route.delivery_man, 
          delivery_man: users.find((user) => user.id == route.delivery_man)?.name || '',
        });
      }
    }

    setUsersColumns([
      {field: 'name', headerName: 'Nome', width: 0.2 * 1132},
      {field: 'visit1', headerName: '1ª Visita', width: 0.15 * 1132},
      {field: 'visit2', headerName: '2ª Visita', width: 0.15 * 1132},
      {field: 'cancelment', headerName: 'Cancelamento 1', width: 0.15 * 1132},
      {field: 'cancelment2', headerName: 'Cancelamento 2', width: 0.15 * 1132},
      {field: 'total', headerName: 'Total', width: 0.2 * 1132},
    ]);

    setTotals(newTotals);
    setPhase1Columns([
      {field: 'route', headerName: 'Rota', width: 0.1 * 1132},
      {
        field: 'visit1',
        headerName: '1ª Visita',
        width: 0.075 * 1132,
        sortComparator: (v1, v2) => {
          return v1 - v2;
        },
      },
      {
        field: 'visit2',
        headerName: '2ª Visita',
        width: 0.075 * 1132,
        sortComparator: (v1, v2) => {
          return v1 - v2;
        },
      },
      {
        field: 'cancelment',
        headerName: '1T Cancelamentos',
        width: 0.15 * 1132,
        sortComparator: (v1, v2) => {
          return v1 - v2;
        },
        renderCell: (params) => {
          const { row } = params;
          return (
            <TextField
              label='Cancelamentos 1T'
              placeholder='Cancelamentos 1T'
              type='number'
              sx={{
                mt: 1,
                mb: 1,
                width: '70%'
              }}
              value={row.cancelment || '0'}
              onChange={(e) => {
                row.cancelment = e.target.value;
                if (row.cancelment < 0) {
                  row.cancelment = 0;
                }
                row.total = +row.visit1 + +row.visit2 + +row.cancelment + +row.cancelment2;
                setRoutes(routes);
                setTotals([...newTotals]);
              }}
            />
          );
        },
      },
      {
        field: 'cancelment2',
        headerName: '2T Cancelamentos',
        width: 0.15 * 1132,
        sortComparator: (v1, v2) => {
          return v1 - v2;
        },
        renderCell: (params) => {
          const { row } = params;
          return (
            <TextField
              label='Cancelamentos 2T'
              placeholder='Cancelamentos 2T'
              type='number'
              sx={{
                mt: 1,
                mb: 1,
                width: '70%'
              }}
              value={row.cancelment2 || '0'}
              onChange={(e) => {
                row.cancelment2 = e.target.value;
                if (row.cancelment2 < 0) {
                  row.cancelment2 = 0;
                }
                row.total = +row.visit1 + +row.visit2 + +row.cancelment + +row.cancelment2;
                setRoutes(routes);
                setTotals([...newTotals]);
              }}
            />
          );
        },
      },
      {
        field: 'total',
        headerName: 'Total',
        width: 0.15 * 1132,
        sortComparator: (v1, v2) => {
          return v1 - v2;
        },
      },
      {
        field: 'delivery_man',
        headerName: 'Entregador',
        width: 0.3 * 1132,
        renderCell: (params) => {
          const { row } = params;
          return (
            <FormControl
              fullWidth
              sx={{
                mt: 1,
                mb: 1,
              }}
            >
              <InputLabel>Entregador</InputLabel>
              <Select 
                fullWidth
                label='Entregador'
                placeholder='Entregador'
                value={row.delivery_man_id || ''}
                onChange={(e) => {
                  row.delivery_man_id = e.target.value;
                  row.delivery_man = users.find(u => u.id == row.delivery_man_id)?.name;
                  setRoutes(routes);
                  setTotals([...newTotals]);
                }}
              >
                <MenuItem key={0} value='0'>Sem Entregador</MenuItem>
                {users.filter(u => u.roles.includes('entregador')).map((user) => (
                  <MenuItem key={+user.id} value={`${user.id}`}>{user.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
        sortComparator: (v1, v2) => {
          if (!v1 && !v2) {
            return 0;
          }
          if (!v1 && v2) {
            return -1;
          }
          if (v1 && !v2) {
            return 1;
          }
          return v1.localeCompare(v2);
        },
      },
    ]);
    setPhase(1);
  }, [routes, movements, users, totals]);

  const save = useCallback(async () => {
    setExporting(true);

    const formData = new FormData();

    const dateBlob = new Blob([JSON.stringify(date.format('YYYY-MM-DD'))], { type: "application/json" });
    formData.append('date', dateBlob);

    const totalsBlob = new Blob([JSON.stringify(totals)], { type: "application/json" });
    formData.append('totals', totalsBlob);

    const data = [];
    for (let idx = 0; idx < ctxFiles.length; idx++) {
      let page = 0;
      const movementsToExport = movements.filter((movement) => movement.ctxIdx === idx);
      for (const movement of movementsToExport) {
        movement.pdfPage = page++;
        data.push(movement);
      }
    }
    data.push(...movements.filter(m => m.type === 'visita2'));

    const dataBlob = new Blob([JSON.stringify(data)], { type: "application/json" });
    formData.append('data', dataBlob);

    const pdfT1Blob = await new Response(ctxFiles[0]).blob();
    formData.append('pdf_t1', pdfT1Blob);

    const pdfT2Blob = await new Response(ctxFiles[1]).blob();
    formData.append('pdf_t2', pdfT2Blob);

    const res = await apiCall('movements', 'post', navigate, formData);

    setExporting(false);
    setPhase(3);
  }, [ctxFiles, movements, totals, dateMode, navigate]);

  return (
    <>
      <Page title='Novo Movimento'>
        {loading > 0 && (
          <>
            <Backdrop open={true} sx={{ zIndex: 99 }} />
            <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-20px', marginLeft: '-20px', zIndex: 999}} />
          </>
        )}
        <Paper sx={{ padding: '1rem'}}>
          <Typography sx={{
            fontWeight: '900',
            fontSize: '1.5rem',
          }}>Novo Movimento</Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box sx={{
            padding: '1rem',
          }}>
            {phase === 0 ? (
              <>
                <Box sx={{mt:0, mb: 2}}>
                  <DatePicker
                    label='Data'
                    value={date}
                    onChange={(newDate) => setDate(newDate)}
                  />
                </Box>
                <DragAndDrop files={files} setFiles={setFiles} />
                <Button sx={{mt:2, mr:2}} color='info' onClick={() => setFiles([])}>Cancelar</Button>
                <Button sx={{mt:2}} variant='contained' color='primary' onClick={() => processFiles()}>Converter</Button>
                {errors.length > 0 && <><div style={{display: 'block', marginTop: '1rem', width: '50%'}}><ErrorBox errors={errors} /></div></>}
                {movements.length > 0 && (
                  <>
                    <div style={{marginTop: '2rem'}}>
                      <BetterTable columns={columns} rows={rows} />
                    </div>
                    <div style={{marginTop: '2rem', textAlign:'right'}}>
                      <Button variant='contained' color='primary' disabled={!canProceed} onClick={() => moveToPhase1()}>Avançar</Button>
                    </div>
                  </>
                )}
              </>
            ) : phase === 1 ? (
              <>
                <Box sx={{mt:2, mb: 2}}>
                  <BetterTable rowHeight={40} columns={usersColumns} rows={usersRows} />
                </Box>
                <Box sx={{mt:2, mb: 2}}>
                  <BetterTable columns={phase1Columns} rows={totals} />
                </Box>
                <div style={{marginTop: '2rem'}}>
                  <div style={{textAlign:'left', width: '50%', display:'inline-block'}}>
                    <Button color='info' onClick={() => setPhase(0)}>Voltar</Button>
                  </div>
                  <div style={{textAlign:'right', width: '50%', display:'inline-block'}}>
                    <Button variant='contained' color='primary' onClick={() => setPhase(2)} disabled={!canProceedToPhase2}>Avançar</Button>
                  </div>
                </div>
              </>
            ) : phase === 2 ? (
              <Box sx={{
                  mt: 2,
                  mb: 2,
                  padding: '2rem',
                  display: 'inline-flex',
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: '#f5f5f5',
                  border: '8px solid #e0e0e0',
                  '&:hover': {
                    backgroundColor: '#fff',
                    borderColor: '#0288d1',
                    color: '#0288d1',
                  }
                }} onClick={() => save()}>
                  <UploadFileOutlined sx={{fontSize: '5rem'}} />
                  <Typography sx={{
                    fontWeight: '900',
                    fontSize: '2rem',
                  }}>Salvar</Typography>
                </Box>
            ) : (
              <>
                <Box sx={{
                  mt: 2,
                  mb: 2,
                  mr: 0,
                  ml: 0,
                  padding: '2rem',
                  display: 'inline-flex',
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f5f5f5',
                  border: '8px solid #e0e0e0',
                }}>
                  <Done sx={{fontSize: '5rem'}} />
                  <Typography sx={{
                    fontWeight: '900',
                    fontSize: '2rem',
                  }}>Movimento enviado com sucesso!</Typography>
                </Box>
                <div style={{ textAlign: 'right', width: '100%', display: 'inline-block' }}>
                    <Button sx={{ mt: 2 }} variant='contained' color='primary' onClick={() => {
                      setDate(dayjs());
                      setCtxFiles([]);
                      setFiles([]);
                      setErrors([]);
                      setCanProceed(false);
                      setCanProceedToPhase2(false);
                      setPhase(0);
                      setTotals([]);
                      setPhase1Columns([]);
                      setUsersColumns([]);
                      setExporting(false);
                      loadRoutes();
                      loadMovements();
                      setMovements([]);
                    }}>Enviar novo</Button>
                </div>
              </>
            )}
          </Box>
        </Paper>
      </Page>
    </>
  );
}
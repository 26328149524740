// Based on: https://mui.com/base-ui/react-textarea-autosize/

import * as React from 'react';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 320px;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 2px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  width: 100%;

  &:hover {
    border-color: ${grey[600]};
  }

  &:focus {
    border-color: ${blue[600]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

export const StyledTextareaAutosize = ({
  value, onChange, placeholder='', minRows=3, maxRows=10, sx={}
}) =>{
  const changeHandler = React.useCallback((e) => {
    onChange(e);
  }, [onChange]);

  return <StyledTextarea 
    placeholder={placeholder}
    minRows={minRows}
    maxRows={maxRows}
    value={value}
    onChange={changeHandler}
    sx={sx}
  />;
}
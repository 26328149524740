import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const WithAuth = ({ children }) => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem('token') && localStorage.getItem('user');

  useEffect(() => {
    if (!isLoggedIn) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/app/login');
    }
  }, [isLoggedIn, navigate]);

  if (!isLoggedIn) {
    return null;
  }

  return children;
};

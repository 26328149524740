import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import * as yup from 'yup';
import { ErrorBox } from './ErrorBox';
import { apiCall } from '../services/apiCall';

export const AddCepRange = ({doneCb, routes}) => {
  const [neighborhood, setNeighborhood] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [route, setRoute] = useState('0');
  const [errors, setErrors] = useState([]);
  const routeOptions = [
    {id: 0, name: 'Sem rota vinculada'},
    ...routes
  ];
  const navigate = useNavigate();

  const addCepRange = useCallback(async () => {
    const schema = yup.object().shape({
      neighborhood: yup.string(),
      start: yup.string().required('CEP inicial é obrigatório'),
      end: yup.string().required('CEP final é obrigatório'),
      route: yup.number(),
    });

    try {
      await schema.validate({start, end, route});
      const data = {
        neighborhood,
        range_start: start,
        range_end: end,
      };
      if (route !== '0') {
        data.route = route;
      }

      await apiCall('cep-ranges', 'post', navigate, data)
      doneCb(true);
    } catch (ex) {
      if (ex instanceof yup.ValidationError) {
        setErrors(ex.errors);
      } else {
        setErrors(ex.response.data.errors);
      }
    }
  }, [neighborhood, start, end, route, navigate, doneCb]);

  return (
    <Dialog open={true} onClose={() => doneCb(false)}>
      <DialogTitle>Adicionar Rota</DialogTitle>
      <DialogContent sx={{minWidth: 500}}>
        <TextField
          fullWidth
          label='Bairro'
          placeholder='Bairro'
          type='text'
          sx={{
            mt: 2,
            mb: 1,
          }}
          value={neighborhood}
          onChange={(e) => setNeighborhood(e.target.value)}
        />
        <TextField
          label='CEP Inicial'
          placeholder='CEP Inicial'
          type='text'
          sx={{
            mt: 1,
            mb: 1,
            width: '47.5%',
            mr: '2.5%',
          }}
          value={start}
          onChange={(e) => setStart(e.target.value)}
        />
        <TextField
          label='CEP Final'
          placeholder='CEP Final'
          type='text'
          sx={{
            mt: 1,
            mb: 1,
            width: '47.5%',
            ml: '2.5%',
          }}
          value={end}
          onChange={(e) => setEnd(e.target.value)}
        />
        <FormControl fullWidth
          sx={{
            mt: 1,
            mb: 1,
          }}
        >
          <InputLabel>Rota</InputLabel>
          <Select 
            fullWidth
            label='Rota'
            placeholder='Rota'
            value={route}
            onChange={(e) => setRoute(e.target.value)}
          >
            {routeOptions.map((route, index) => (
              <MenuItem key={index} value={route.id}>{route.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {errors.length > 0 && (
          <FormGroup sx={{ mt: 2}}>
            <ErrorBox errors={errors} />
          </FormGroup>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => doneCb(false)}>Cancelar</Button>
        <Button onClick={() => addCepRange()} variant='contained'>Salvar</Button>
      </DialogActions>

    </Dialog>
  );
};
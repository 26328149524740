import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
} from '@mui/material';
import { ErrorBox } from './ErrorBox';
import { apiCall } from '../services/apiCall';

export const DeleteRoute = ({ doneCb, route }) => {
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const deleteRoute = async () => {
    try {
      await apiCall(`routes/${route.id}`, 'delete', navigate);
      doneCb(true);
    } catch (err) {
      console.log(err);
      setErrors(err.response.data.errors);
    }
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Excluir Rota</DialogTitle>
      <DialogContent
        sx={{
          minWidth: 500,
        }}
      >
        <DialogContentText>
          Você tem certeza que deseja excluir a rota <b>{route.name}</b>?
        </DialogContentText>
        {errors.length > 0 && 
          <FormGroup sx={{ mt: 2}}>
            <ErrorBox errors={errors} />
          </FormGroup>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => doneCb(false)}>Cancelar</Button>
        <Button onClick={deleteRoute} color='error'>Excluir</Button>
      </DialogActions>
    </Dialog>
  );
};
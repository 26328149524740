import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { apiCall } from '../services/apiCall';

export const DeleteNotary = ({ doneCb, notary }) => {
  const navigate = useNavigate();

  const deleteNotary = async () => {
    try {
      await apiCall(`notaries/${notary.id}`, 'delete', navigate);
      doneCb(true);
    } catch (err) {
      console.log(err);
      doneCb(false);
    }
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Excluir Cartório</DialogTitle>
      <DialogContent
        sx={{
          minWidth: 500,
        }}
      >
        <DialogContentText>
          Você tem certeza que deseja excluir o cartório <b>{notary.name}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => doneCb(false)}>Cancelar</Button>
        <Button onClick={deleteNotary} color='error'>Excluir</Button>
      </DialogActions>
    </Dialog>
  );
};
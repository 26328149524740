import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { WithAuth } from './components/WithAuth';
import { Login } from './components/Login';
import { Movements } from './components/Movements';
import { Records } from './components/Records';
import { Delivery } from './components/Delivery';
import { NewMovement } from './components/NewMovement';
import { SingleMovement } from './components/SingleMovement';
import { Failures } from './components/Failures';
import { Payments } from './components/Payments';
import { SingleCancelment } from './components/SingleCancelment';
import { Dashboard } from './components/Dashboard';


import 'dayjs/locale/pt-br';

import './App.css';

import '@mui/material';

import 'react-toastify/dist/ReactToastify.css';

// fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/900.css';

const theme = createTheme({
  /*components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '0',
        },
        contained: {
          backgroundColor: '#c67422',
          color: '#fff',
        },
      },
    },
    // Titles
    MuiTypography: {
      styleOverrides: {
        root: {
         //backgroundColor: '#492910',
          //color: '#fff',
        },
      },
    },
  }, 
  */
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
        <Router>
          <Routes>
            <Route path="/app/dashboard" element={<WithAuth><Dashboard /></WithAuth>} />
            <Route path="/app/login" element={<Login />} />
            <Route path="/app/movimentos" element={<WithAuth><Movements /></WithAuth>} />
            <Route path="/app/movimento/:date" element={<WithAuth><SingleMovement /></WithAuth>} />
            <Route path="/app/cancelamento/:date" element={<WithAuth><SingleCancelment /></WithAuth>} />
            <Route path="/app/novo-movimento" element={<WithAuth><NewMovement /></WithAuth>} />
            <Route path="/app/falhas" element={<WithAuth><Failures /></WithAuth>} />
            <Route path='/app/pagamentos' element={<WithAuth><Payments /></WithAuth>} />
            <Route path="/app/cadastros" element={<WithAuth><Records /></WithAuth>} />
            <Route path="/app/rotas" element={<WithAuth><Delivery /></WithAuth>} />
          </Routes>
        </Router>
        <ToastContainer />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
